import React from "react";

type Props = {
  onSave: () => void;
  onClose: () => void;
};

const Actions = ({ onSave, onClose }: Props) => (
  <div className="flex items-center justify-between p-4">
    <button
      type="button"
      className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      onClick={onClose}>
      Zamknij
    </button>
    <button
      type="button"
      className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
      onClick={onSave}>
      Zapisz
    </button>
  </div>
);

export default Actions;
