import React from "react";
import SelectedFilter from "./SelectedFilter";
import { Filters, UpdateFiltersFn } from "../types";
import { SalesChannel } from "../../../api/types";

type Props = {
  filters: Filters;
  updateFilters: UpdateFiltersFn;
  onClick: () => void;
};

const mapSelectedChannels = (channels: SalesChannel[]): string[] => {
  const result = [];
  if (channels.includes("ONLINE")) {
    result.push("przez Internet")
  }
  if (channels.includes("PHONE")) {
    result.push("przez telefon")
  }
  if (channels.includes("BRANCH")) {
    result.push("w oddziale")
  }
  if (channels.includes("MOBILE_APP")) {
    result.push("w aplikacji mobilnej")
  }
  return result;
};

const SelectedChannels = ({ filters, updateFilters, onClick }: Props) => {
  const channels = mapSelectedChannels(filters.channels);
  if (channels.length === 0) {
    return null;
  }

  const concatenated = channels.join(", ");

  return (
    <SelectedFilter
      text={`Dostępne tylko: ${concatenated}`}
      onSelect={onClick}
      onRemove={() => {
        updateFilters({ channels: [] })
      }} />
  );
};

export default SelectedChannels;
