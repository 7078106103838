import React from "react";
import Navigation from "../components/nav/Navigation";
import Footer from "../components/footer/Footer";
import { Outlet } from "react-router-dom";

const Root = () => (
  <div className="min-h-full">
    <Navigation />

    <Outlet />

    <Footer />
  </div>
);

export default Root;
