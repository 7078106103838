import { AxiosInstance } from "axios";
import banks from "./banks";
import deposits from "./deposits";
import userDeposits from "./userDeposits";

const setAuthorization = (axios: AxiosInstance) => (token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

const clearAuthorization = (axios: AxiosInstance) => () => {
  axios.defaults.headers.common["Authorization"] = undefined;
}

const create = (axios: AxiosInstance) => {
  return {
    banks: banks(axios),
    deposits: deposits(axios),
    userDeposits: userDeposits(axios),

    setAuthorization: setAuthorization(axios),
    clearAuthorization: clearAuthorization(axios)
  }
}

export default create;
