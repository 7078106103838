import React, { PropsWithChildren, useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { useCookiesConsentContext } from "../cookies/CookiesConsentChecker";

const AnalyticsContext = React.createContext({ enabled: false })

export const useAnalyticsContext = () => useContext(AnalyticsContext);

type Props = {};

const AnalyticsInitializer = ({ children }: PropsWithChildren<Props>) => {
  const { consent } = useCookiesConsentContext();

  useEffect(() => {
    if (consent.analytics) {
      ReactGA.initialize("G-X67Z2V3N8C", {
        gaOptions: {
          anonymizeIp: true
        }
      });
    }
  }, [consent.analytics])

  return (
    <AnalyticsContext.Provider value={{ enabled: consent.analytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsInitializer;
