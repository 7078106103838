import React from "react";
import { Bank, UserDeposit } from "../../../api/types";
import BankLogo from "../../bankLogo/BankLogo";

type Props = {
  userDeposit: UserDeposit;
  bank: Bank | null;
};

const ItemDepositName = ({ userDeposit, bank }: Props) => (
  <>
    <div className="w-1/6 m-1 h-8">
      <BankLogo bank={bank} />
    </div>
    <div className="w-5/6 truncate">
      {userDeposit.name}
    </div>
  </>
);

export default ItemDepositName;
