/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import {paths} from "../../routes/links";
import {Link as RouterLink} from "react-router-dom";

type Props = {};

const NewsletterForm = (props: Props) => (
  <form action="https://app.convertkit.com/forms/4930443/subscriptions" method="post">
    <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
      <div className="relative w-full">
        <label htmlFor="email" className="hidden mb-2 text-sm font-medium text-gray-900">Email</label>
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <EnvelopeIcon className="h-5 text-gray-500" />
        </div>
        <input
          name="email_address"
          className="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary focus:border-primary"
          placeholder="Podaj swój adres email" type="email" id="email" required />
      </div>
      <div>
        <button
          type="submit"
          className="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-primary border-primary sm:rounded-none sm:rounded-r-lg hover:bg-primary focus:ring-4 focus:ring-primary ">
          Wyślij
        </button>
      </div>
    </div>
    <div className="mx-auto max-w-screen-sm text-sm text-left text-gray-500 newsletter-form-footer">
      Zobacz jak przetwarzamy twoje dane w <RouterLink target="_blank" to={paths.privacyPolicy} className="font-medium text-primary hover:underline">polityce prywatności</RouterLink>.
    </div>
  </form>
);

export default NewsletterForm;
