import React from "react";
import { Attachment } from "../../../api/types";
import AttachmentsList from "./AttachmentsList";

type Props = {
  attachments: Attachment[];
};

const AttachmentsSection = ({ attachments }: Props) => {
  if (attachments.length === 0) {
    return null;
  }

  return (
    <div>
      <div>Dokumenty</div>
      <AttachmentsList attachments={attachments} />
    </div>
  )
};

export default AttachmentsSection;
