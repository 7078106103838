import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import BanksSection from "./BanksSection";
import { Bank } from "../../../../api/types";
import { Exclusions, Filters, UpdateFiltersFn } from "../../types";
import AvailabilitySection from "./AvailabilitySection";
import Actions from "./Actions";
import ExclusionsSection from "./ExclusionsSection";

type Props = {
  isOpen: boolean;
  onClose: () => void;

  filters: Filters;
  updateFilters: UpdateFiltersFn;
  banks: Bank[];
  defaultOpenSections: {
    banks: boolean;
    exclusions: boolean;
    availability: boolean;
  }
};

const AdditionalFiltersModal = ({ isOpen, onClose, filters, updateFilters, banks, defaultOpenSections }: Props) => {
  const [selectedBanksIds, setSelectedBanksIds] = useState(filters.banksIds);
  const [exclusions, setExclusions] = useState<Exclusions>(filters.exclusions);
  const [selectedChannels, setSelectedChannels] = useState(filters.channels);

  useEffect(() => {
    setSelectedBanksIds(filters.banksIds);
    setSelectedChannels(filters.channels);
    setExclusions(filters.exclusions);
  }, [isOpen, filters.banksIds, filters.channels, filters.exclusions]);


  const onSave = () => {
    updateFilters({
      banksIds: selectedBanksIds,
      channels: selectedChannels,
      exclusions: exclusions
    })
    onClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto min-w-full">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-6xl transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 p-4"
                >
                  Dodatkowe filtry
                </Dialog.Title>

                <BanksSection
                  defaultOpen={defaultOpenSections.banks}
                  banks={banks}
                  selectedBanksIds={selectedBanksIds}
                  onChange={setSelectedBanksIds}
                />
                <ExclusionsSection
                  defaultOpen={defaultOpenSections.exclusions}
                  exclusions={exclusions}
                  onChange={setExclusions} />
                <AvailabilitySection
                  defaultOpen={defaultOpenSections.availability}
                  channels={selectedChannels}
                  onChange={setSelectedChannels} />

                <Actions onSave={onSave} onClose={onClose} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AdditionalFiltersModal;
