export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const dateFormat = new Intl.DateTimeFormat("pl-PL");

const percentageFormatter = new Intl.NumberFormat("pl-PL", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const amountFormatter = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "PLN",
  maximumFractionDigits: 0,
});

const relativeDaysFormatter = new Intl.RelativeTimeFormat("pl-PL", { style: "short" })

export function formatPercentage(amount: number) {
  return percentageFormatter.format(amount);
}

export function formatAmount(amount: number) {
  return amountFormatter.format(amount);
}

export function formatDateFromString(isoDateString: string) {
  const date = new Date(isoDateString);
  return formatDate(date);
}

export function formatDate(date: Date) {
  return dateFormat.format(date);
}

export function formatRelativeDays(numberOfDays: number) {
  return relativeDaysFormatter.format(numberOfDays, "day");
}

export function getToday() {
  return new Date(new Date().setHours(0, 0, 0, 0));
}
