import React from "react";
import Section from "../Section";
import { BuildingOfficeIcon, DevicePhoneMobileIcon, GlobeAltIcon, PhoneIcon } from "@heroicons/react/24/outline";
import AvailabilityItem from "./AvailabilityItem";
import { SalesChannel } from "../../../../api/types";

type Props = {
  defaultOpen: boolean;
  channels: SalesChannel[];
  onChange: (channels: SalesChannel[]) => void;
};

const toggle = (channels: SalesChannel[], channel: SalesChannel, selected: boolean) => {
  if (selected) {
    return channels.filter(c => c !== channel);
  }
  return [...channels, channel];
}

const AvailabilitySection = ({ defaultOpen, channels, onChange }: Props) => {
  const online = channels.includes("ONLINE");
  const phone = channels.includes("PHONE");
  const branch = channels.includes("BRANCH");
  const mobileApp = channels.includes("MOBILE_APP");

  return (
    <Section header="Dostępność" defaultOpen={defaultOpen}>
      <div className="flex flex-wrap">
        <AvailabilityItem
          selected={online}
          onClick={() => onChange(toggle(channels, "ONLINE", online))}
          label="Internet"
          icon={GlobeAltIcon} />
        <AvailabilityItem
          selected={mobileApp}
          onClick={() => onChange(toggle(channels, "MOBILE_APP", mobileApp))}
          label="Aplikacja mobilna"
          icon={DevicePhoneMobileIcon} />
        <AvailabilityItem
          selected={branch}
          onClick={() => onChange(toggle(channels, "BRANCH", branch))}
          label="Oddział"
          icon={BuildingOfficeIcon} />
        <AvailabilityItem
          selected={phone}
          onClick={() => onChange(toggle(channels, "PHONE", phone))}
          label="Telefon"
          icon={PhoneIcon} />
      </div>
    </Section>
  );
};

export default AvailabilitySection;
