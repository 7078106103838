import React, { PropsWithChildren } from "react";
import { classNames } from "../../utils";

type Props = {
  percentage: number;
  open: boolean;
}

const ProgressBar = ({ percentage, open, children }: PropsWithChildren<Props>) => (
  <div className="text-left">
    <div>{children}</div>
    {percentage < 100 && (
      <div
        className={classNames(
          "w-full bg-green-400",
          open ? "h-3" : "h-1"
        )}
        style={{ width: `${percentage}%` }}
      >
        {open && (
          <div className="absolute w-full text-center text-[8px] leading-[14px] text-gray-600">
            {percentage} %
          </div>
        )}
      </div>
    )}
  </div>
);

export default ProgressBar;
