import React, { useState } from "react";
import DepositsListHeader from "./list/DepositsListHeader";
import { LoadingStatus } from "./useUserDeposits";
import { Bank, UserDeposit } from "../../api/types";
import EmptyList from "./list/EmptyList";
import DepositsList from "./list/DepositsList";
import ClosedDepositsList from "./ClosedDepositsList";
import LoadingBox from "../loading/LoadingBox";
import { isBefore, parseISO } from "date-fns";
import SectionHeader from "./SectionHeader";
import EditUserDepositModal from "../userDepositModal/EditUserDepositModal";
import DepositsSectionContent from "./DepositsSectionContent";

const splitFinishedDeposits = (deposits: UserDeposit[]) => {
  const today = new Date();
  return deposits.reduce((result, deposit) => {
    if (!isBefore(today, parseISO(deposit.end))) {
      result[0].push(deposit);
    } else {
      result[1].push(deposit);
    }
    return result;
  }, [[], []] as UserDeposit[][]);
};

type Props = {
  status: LoadingStatus;
  banks: Bank[];
  userDeposits: UserDeposit[];
  closedUserDeposits: UserDeposit[];
  onAddDeposit: () => void;
  updateUserDeposit: (userDeposit: UserDeposit) => Promise<any>;
  closeUserDeposit: (userDeposit: UserDeposit) => Promise<any>;
  deleteUserDeposit: (userDeposit: UserDeposit) => Promise<any>;
}

const DepositsSection = ({
  status,
  userDeposits,
  banks,
  closedUserDeposits,
  onAddDeposit,
  updateUserDeposit,
  closeUserDeposit,
  deleteUserDeposit
}: Props) => {
  const [selectedDeposit, setSelectedDeposit] = useState<UserDeposit | null>(null);
  const [finished, notFinished] = splitFinishedDeposits(userDeposits);

  const onUserDepositUpdate = async (userDeposit: UserDeposit) => {
    await updateUserDeposit(userDeposit);
    setSelectedDeposit(null);
  }

  const onUserDepositDelete = async (userDeposit: UserDeposit) => {
    await deleteUserDeposit(userDeposit);
  }

  const onUserDepositClose = async (userDeposit: UserDeposit) => {
    await closeUserDeposit(userDeposit);
  }

  return (
    <section>
      <LoadingBox description="Wczytywanie lokat" loading={status === "loading"}>
        <DepositsSectionContent status={status}>
          {finished.length > 0 && (
            <div className="py-2">
              <SectionHeader text="Zakończone lokaty" />
              <DepositsList
                deposits={finished}
                banks={banks}
                onUserDepositEdit={setSelectedDeposit}
                onUserDepositClose={onUserDepositClose}
                onUserDepositDelete={onUserDepositDelete} />
            </div>
          )}

          <DepositsListHeader onAddDeposit={onAddDeposit} />

          {status === "success" && userDeposits.length === 0 ? (
            <EmptyList />
          ) : (
            <DepositsList
              deposits={notFinished}
              banks={banks}
              onUserDepositEdit={setSelectedDeposit}
              onUserDepositClose={onUserDepositClose}
              onUserDepositDelete={onUserDepositDelete} />
          )}

          <ClosedDepositsList
            closedDeposits={closedUserDeposits}
            banks={banks}
            onUserDepositEdit={setSelectedDeposit}
            onUserDepositClose={onUserDepositClose}
            onUserDepositDelete={onUserDepositDelete} />

          {selectedDeposit && (
            <EditUserDepositModal
              userDeposit={selectedDeposit}
              banks={banks}
              onSave={onUserDepositUpdate}
              onClose={() => {
                setSelectedDeposit(null);
              }} />
          )}
        </DepositsSectionContent>
      </LoadingBox>
    </section>
  );
};

export default DepositsSection;
