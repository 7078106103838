import React, { PropsWithChildren, ReactNode } from "react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";

type Props = {
  defaultOpen?: boolean;
  header: ReactNode;
};

const Section = ({ defaultOpen = false, header, children }: PropsWithChildren<Props>) => (
  <Disclosure defaultOpen={defaultOpen}>
    {({ open }) => (
      <>
        <Disclosure.Button
          className="flex w-full justify-start items-center px-4 py-2 text-left text-lg font-medium focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
          <ChevronUpIcon
            className={`${open ? "rotate-180 transform" : ""} h-5 w-5`}
          />
          <span className="pl-3">{header}</span>
        </Disclosure.Button>
        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
          {children}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export default Section;
