import { useEffect, useState } from "react";
import { Bank } from "../../api/types";
import { useApi } from "../../api/context";

const useBanks = () => {
  const [banks, setBanks] = useState<Bank[]>([]);
  const api = useApi();

  useEffect(() => {
    api.banks.get().then(result => {
      setBanks(result);
    })
  }, [api]);

  return banks;
}

export default useBanks;
