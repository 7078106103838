import React from "react";
import { ArrowTopRightOnSquareIcon, DocumentIcon } from "@heroicons/react/24/outline";
import { Attachment } from "../../../api/types";
import InfoItem from "./InfoItem";

type Props = {
  attachments: Attachment[];
};

const AttachmentsList = ({ attachments }: Props) => {
  return (
    <div>
      {attachments.map((attachment) => (
        <InfoItem
          key={attachment.name}
          icon={(
            <DocumentIcon className="h-4" />
          )}
          content={(
            <a href={attachment.value} className="text-sm hover:underline" target="_blank" rel="noreferrer">
              {attachment.name} <ArrowTopRightOnSquareIcon className="h-3 inline-block" />
            </a>
          )}
        />
      ))}
    </div>
  )
};

export default AttachmentsList;
