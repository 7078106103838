import React from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";

type Props = {
  open: boolean
};

const Line = () => (
  <div className="flex flex-1 border-b border-gray-200"></div>
);

const SeparatorHeader = ({ open }: Props) => (
  <Disclosure.Button
    className="flex flex-1 w-full items-center px-2">
    <Line />
    <div className="flex items-center px-2 text-gray-500">
      <small>więcej</small>
      <ChevronRightIcon
        className={`${open ? "rotate-90 transform" : ""} h-3 w-3 ml-2`} />
    </div>
    <Line />
  </Disclosure.Button>
);

export default SeparatorHeader;
