import React from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Bank } from "../../api/types";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";

type Props = {
  id: string;
  value: Bank | null;
  onChange: (bank: Bank | null) => void;
  banks: Bank[];
};

const BankSelect = ({ id, value, onChange, banks }: Props) => {
  const sortedBanks = banks.sort((a, b) => b.name.localeCompare(a.name));

  return (
    <Listbox
      value={value}
      onChange={(value) => {
        onChange(value);
      }}>
      <div className="relative mt-1 max-w-xs w-full">
        <Listbox.Button
          id={id}
          className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0">
          <span className="block truncate">
            {value ? value.name : "Inny"}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {sortedBanks.map((bank) => (
              <Listbox.Option
                key={bank.uid}
                value={bank}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-primary text-white" : "text-gray-900"
                  }`
                }>
                {bank.name}
              </Listbox.Option>
            )
            )}
            <Listbox.Option
              value={null}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? "bg-primary text-white" : "text-gray-900"
                }`
              }>
              Inny
            </Listbox.Option>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default BankSelect;
