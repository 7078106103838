import React from "react";
import { Bank } from "../../../api/types";
import BankLogo from "../../bankLogo/BankLogo";
import SelectableItem from "../additional/modal/SelectableItem";

type Props = {
  bank: Bank;
  selected: boolean;
  onClick: (bank: Bank) => void;
};

const BankItem = ({ bank, selected, onClick }: Props) => (
  <div className="flex-shrink-0 w-16 h-10 m-1 bg-white">
    <SelectableItem selected={selected} onClick={() => onClick(bank)}>
      <BankLogo bank={bank} />
    </SelectableItem>
  </div>
);

export default BankItem;
