import React from "react";
import { Deposit } from "../../api/types";
import { formatPercentage } from "../utils";

type Props = {
  deposit: Deposit;
};

const AnnualPercentageRate = ({ deposit }: Props) => (
  <h2 className="text-2xl lg:ml-6">
    {formatPercentage(deposit.annualPercentageRate)}
    <span className="text-lg ml-0.5">%</span>
  </h2>
);

export default AnnualPercentageRate;
