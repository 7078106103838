import React from "react";
import ItemValue from "./ItemValue";
import { classNames, formatRelativeDays } from "../../utils";

type Props = {
  daysLeft: number;
};

const DepositDaysLeft = ({ daysLeft }: Props) => {
  const warning = daysLeft <= 0;
  return (
    <ItemValue
      label="Zakończenie"
      value={(
        <div className={classNames(warning && "font-semibold text-accent")}>
          {daysLeft === 0 ? "dzisiaj" : formatRelativeDays(daysLeft)}
        </div>
      )} />
  );
};

export default DepositDaysLeft;
