import React, { useState } from "react";
import { Bank, Deposit } from "../../api/types";
import DepositItem from "./DepositItem";
import SelectedDepositModal from "./selectedDeposit/SelectedDepositModal";
import { Filters } from "../filter/types";
import useDeposits from "./useDeposits";
import EmptyDepositsList from "./EmptyDepositsList";
import LoadingBox from "../loading/LoadingBox";
import useAnalyticsTracker from "../analytics/useAnalyticsTracker";
import LoadingError from "./LoadingError";

type Props = {
  banks: Bank[];
  filters: Filters;
}

const DepositsList = ({ banks, filters }: Props) => {
  const { depositSelected } = useAnalyticsTracker();
  const { status, deposits } = useDeposits(filters);
  const [selectedDeposit, setSelectedDeposit] = useState<Deposit | null>(null);

  return (
    <div className="mt-2 space-y-1">
      <LoadingBox description="Wyszukiwanie lokat" loading={status === "loading"}>
        {status === "success" && deposits.length === 0 && (
          <EmptyDepositsList />
        )}
        {status === "error" && (
          <LoadingError />
        )}

        {deposits.map(deposit => {
          const bank = banks.find(b => b.uid === deposit.bankId) ?? null;
          return (
            <DepositItem
              key={deposit.uid}
              selectedAmount={filters.amount}
              deposit={deposit}
              bank={bank}
              onSelect={(deposit) => {
                depositSelected(deposit, bank);
                setSelectedDeposit(deposit);
              }} />
          );
        })}
      </LoadingBox>

      {selectedDeposit && (
        <SelectedDepositModal
          deposit={selectedDeposit}
          selectedAmount={filters.amount}
          banks={banks}
          onClose={() => setSelectedDeposit(null)} />
      )}
    </div>
  );
};

export default DepositsList;
