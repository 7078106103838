import React, {PropsWithChildren, useEffect} from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import {useNavigate, useSearchParams} from "react-router-dom";
import { User } from "@auth0/auth0-spa-js";
import { AuthAppState } from "./types";
import { paths } from "../../routes/links";
import AuthLoader from "./AuthLoader";
import {useCookies} from "react-cookie";
import {useCookiesConsentContext} from "../cookies/CookiesConsentChecker";

type OnRedirectCallback = (appState?: AuthAppState, user?: User) => void;

const Auth = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams()
  const { consent } = useCookiesConsentContext();
  const [cookies, setCookie] = useCookies(["referralId"]);

  useEffect(() => {
    if(searchParams.has("refId") && consent.analytics) {
      const referralId = searchParams.get("refId")
      setCookie("referralId", referralId, { maxAge: 86400 })
    }
  })

  const onRedirectCallback: OnRedirectCallback = (appState, user) => {
    if (appState) {
      if (appState.action?.type === "SAVE_DEPOSIT") {
        navigate(paths.savedDeposits,
          {
            state: {
              action: appState.action
            }
          });
      } else if (appState.returnTo) {
        navigate(appState.returnTo);
      }
    }
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        referral_id: cookies.referralId
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <AuthLoader>
        {children}
      </AuthLoader>
    </Auth0Provider>
  );
};

export default Auth;
