import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../login/LoginButton";
import LogoutButton from "../login/LogoutButton";


const UserNavDesktop = () => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  return (
    <div className="hidden md:block">
      <div className="ml-4 flex items-center md:ml-6">
        {!isLoading && !isAuthenticated && (
          <LoginButton className="block px-4 py-2 text-sm text-gray-700 rounded-md font-medium hover:bg-gray-700 hover:text-white w-full">
            Zaloguj się
          </LoginButton>
        )}
        {!isLoading && isAuthenticated &&
          <Menu as="div" className="relative ml-3">
            <div>
              <Menu.Button
                className="flex items-center text-end">
                <span className="sr-only">Otwórz menu użytkownika</span>
                <UserCircleIcon className="h-8 w-8 rounded-full" />
                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-dark">{user?.name}</div>
                  <div className="text-sm font-medium leading-none text-gray-400">{user?.email}</div>
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                <Menu.Item>
                  <LogoutButton className="block px-4 py-2 text-sm text-gray-700 rounded-md font-medium hover:bg-gray-700 hover:text-white w-full" />
                </Menu.Item>

              </Menu.Items>
            </Transition>
          </Menu>

        }
      </div>
    </div>
  );
};

export default UserNavDesktop;
