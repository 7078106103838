import React, { ReactNode } from "react";

type Props = {
  label: string | ReactNode;
  value: string | ReactNode;
};

const ItemValue = ({ label, value }: Props) => (
  <>
    <div className="text-sm text-gray-400 lg:hidden">{label}</div>
    <div>{value}</div>
  </>
);

export default ItemValue;
