import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import create from "./api/factory";
import axios from "axios";
import queryString from "query-string";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  paramsSerializer: {
    serialize: function (params: any) {
      return queryString.stringify(params, { arrayFormat: "comma" })
    }
  }
});
const api = create(axiosInstance);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App api={api} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
