import React, { PropsWithChildren } from "react";
import { LoadingStatus } from "./useUserDeposits";
import LoadingError from "./LoadingError";

type Props = {
  status: LoadingStatus;
}

const DepositsSectionContent = ({ status, children }: PropsWithChildren<Props>) => {
  if (status === "error") {
    return (
      <LoadingError />
    )
  }

  return <>{children}</>;
};

export default DepositsSectionContent;
