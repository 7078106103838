import React, { PropsWithChildren, useDeferredValue } from "react";
import Indicator from "./Indicator";

type Props = {
  loading: boolean;
  description: string;
  blocking?: boolean
};

const LoadingBox = ({ loading, description, blocking, children }: PropsWithChildren<Props>) => {
  const deferredLoading = useDeferredValue(loading);

  return (
    <div className="relative min-h-[10rem]">
      {deferredLoading && (
        <div className="absolute inset-0 bg-black bg-opacity-10 rounded-md flex justify-center">
          <div className="mt-16">
            <Indicator description={description} />
          </div>
        </div>
      )}

      {(loading && blocking) ? null : children}
    </div>
  );
};

export default LoadingBox;
