import React, { PropsWithChildren } from "react";
import { Disclosure, Switch } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";

type Props = {
  name: string;
  selected: boolean
  onChange: (value: boolean) => void
};

const CookieSection = ({ name, selected, onChange, children }: PropsWithChildren<Props>) => (
  <Disclosure>
    {({ open }) => (
      <>
        <div className="flex w-full justify-between">
          <Disclosure.Button
            className="flex justify-start items-center px-4 py-2 text-left">
            <ChevronUpIcon
              className={`${open ? "rotate-180 transform" : ""} h-5 w-5`}
            />
            <span className="pl-3">{name}</span>
          </Disclosure.Button>

          <Switch
            checked={selected}
            onChange={onChange}
            className={`${
              selected ? "bg-blue-600" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Pliki cookies: {name}</span>
            <span
              className={`${
                selected ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>

        </div>
        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
          {children}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export default CookieSection;
