import React from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { NavElement } from "./types";
import { classNames } from "../utils";
import { paths } from "../../routes/links";
import { NavLink } from "react-router-dom";
import UserNavDesktop from "./UserNavDesktop";
import UserNavMobile from "./UserNavMobile";
import Logo from "../logo/Logo";

const navigation: NavElement[] = [
  { name: "Ranking lokat", href: paths.deposits, current: true },
  { name: "Twoje lokaty", href: paths.savedDeposits, current: false }
];

const Navigation = () => (
  <Disclosure as="nav" className="bg-light shadow">
    {({ open }) => (
      <>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Logo height={32} />
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  {navigation.map(item => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) => classNames(
                        isActive ? "bg-dark text-light" : "text-dark hover:bg-body hover:text-light",
                        "px-3 py-2 rounded-md text-sm font-medium"
                      )}>
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
            <UserNavDesktop />
            <div className="-mr-2 flex md:hidden">
              {/* Mobile menu button */}
              <Disclosure.Button
                className="inline-flex items-center justify-center rounded-md p-2 text-dark hover:bg-gray-200 hover:text-dark focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300">
                <span className="sr-only">Otwórz menu</span>
                {open ? (
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>
          </div>
        </div>

        <Disclosure.Panel className="md:hidden">
          {({ close }) => (
            <>
              <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {navigation.map(item => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    onClick={() => close()}
                    className={({ isActive }) => classNames(
                      isActive ? "bg-dark text-light" : "text-dark hover:bg-body hover:text-light",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}>
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <UserNavMobile />
            </>
          )}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export default Navigation;
