import React from "react";
import Root from "./Root";
import ErrorPage from "./ErrorPage";
import Deposits from "./Deposits";
import { paths } from "./links";
import { Route, Routes as RoutesDOM } from "react-router-dom";
import SavedDeposits from "./savedDeposits/SavedDeposits";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiesInfo from "./CookiesInfo";
import NewsletterConfirmation from "./NewsletterConfirmation";
import ErrorPage404 from "./ErrorPage404";
import NewsletterSubscribed from "./NewsletterSubscribed";

const Routes = () => (
  <RoutesDOM>
    <Route
      path={paths.root}
      element={<Root />}
      errorElement={<ErrorPage />}>
      <Route
        path={paths.deposits}
        element={<Deposits />}
      />
      <Route
        path={paths.savedDeposits}
        element={<SavedDeposits />}
      />
      <Route
        path={paths.privacyPolicy}
        element={<PrivacyPolicy />}
      />
      <Route
        path={paths.cookiesInfo}
        element={<CookiesInfo />}
      />
      <Route
        path={paths.newsletterSubscribed}
        element={<NewsletterSubscribed />}
      />
      <Route
        path={paths.newsletterConfirmation}
        element={<NewsletterConfirmation />}
      />
      <Route path="*" element={<ErrorPage404 />} />
    </Route>
  </RoutesDOM>
)

export default Routes;
