import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

type Props = {
  text: string;
  onSelect: () => void;
  onRemove: () => void;
};

const SelectedFilter = ({ text, onSelect, onRemove }: Props) => (
  <div
    className="flex items-center py-1 px-2.5 border border-dark text-center whitespace-nowrap bg-primary text-white rounded-full hover:bg-dark">
    <button
      type="button"
      className=""
      onClick={onSelect}>
      {text}
    </button>
    <button
      type="button"
      className="bg-white rounded-full p-1 hover:bg-gray-300 ml-2 -mr-1"
      onClick={onRemove}>
      <XMarkIcon className="h-4 text-black" />
    </button>
  </div>
);

export default SelectedFilter;
