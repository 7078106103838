import React from "react";
import NetInterestTooltip from "../../interest/NetInterestTooltip";

const TableListHeader = () => (
  <div className="hidden lg:flex lg:px-3 items-end text-sm">
    <div className="flex w-1/2 px-1 lg:w-[35%]">
      Nazwa lokaty
    </div>
    <div className="flex w-1/2 px-1 lg:w-[20%]">
      Zakończenie
    </div>
    <div className="flex w-1/2 px-1 lg:w-[15%]">
      Kwota
    </div>
    <div className="flex w-1/2 px-1 lg:w-[15%] truncate">
      Oprocentowanie
    </div>
    <div className="flex w-1/2 px-1 lg:w-[15%]">
      Zysk
      <NetInterestTooltip />
    </div>
  </div>
);

export default TableListHeader;
