import React from "react";

type Props = {};

const LoadingError = (props: Props) => (
  <section className="bg-white min-h-[10rem]">
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="mx-auto max-w-screen-sm text-center">
        <h1 className="mb-4 text-3xl tracking-tight font-extrabold lg:text-6xl text-primary-600">Oops! 🙈</h1>
        <p className="text-lg font-light text-gray-500">
          Nie udało się wczytać lokat. Spróbuj zmienić parametry i odświeżyć stronę.
        </p>

        <a
          href="/"
          className="inline-flex bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">
          Powrót do strony głównej
        </a>
      </div>
    </div>
  </section>
);

export default LoadingError;
