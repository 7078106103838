import React, { useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import AmountSelectOption from "../filter/amount/AmountSelectOption";
import { formatAmount } from "../utils";
import CustomOption from "../filter/amount/CustomOption";
import { usePrevious } from "react-use";

const values = [
  500, 1_000, 2_000, 5_000, 10_000, 20_000, 50_000, 100_000, 150_000
]

type Props = {
  id: string;
  onChange: (amount: number) => void;
  value: number;
};

const AmountInput = ({ id, value, onChange }: Props) => {
  const previousValue = usePrevious<number>(value);
  const [inputValue, setInputValue] = useState(`${value}`);
  const [inputFocused, setInputFocused] = useState(false);

  const onChangeIfDifferent = (newValue: number) => {
    if (newValue !== value) {
      onChange(newValue);
      setInputValue(`${newValue}`)
    }
  }

  const changeIfNumber = (newValue: string | number) => {
    if (typeof newValue === "number") {
      onChangeIfDifferent(newValue);
    } else {
      const stringValueWithoutCurrency = `${newValue}`.replaceAll(/[  (zł)]/g, "");
      if (!/^\d+$/.test(stringValueWithoutCurrency)) {
        onChange(previousValue ?? 1_000);
      } else {
        onChangeIfDifferent(parseInt(stringValueWithoutCurrency, 10));
      }
    }
  }

  // value passed to Combobox is always empty string as a workaround for selecting last active element on enter
  // see: https://github.com/tailwindlabs/headlessui/discussions/1228
  return (
    <Combobox value="" onChange={changeIfNumber}>
      <div className="relative mt-1 max-w-xs">
        <div
          className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          <Combobox.Button className="w-full">
            <Combobox.Input
              id={id}
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              onFocus={() => {
                setInputFocused(true);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                setInputFocused(false);
                changeIfNumber(event.target.value);
              }}
              displayValue={() => {
                if (value) {
                  if (inputFocused) {
                    return `${value}`;
                  } else {
                    return formatAmount(value as unknown as number);
                  }
                }
                return "";
              }}
              onChange={(event) => {
                if (event.target.value && event.target.value.length < 14) {
                  setInputValue(event.target.value);
                }
              }}
            />
            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Combobox.Button>
        </div>
        <Transition
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <CustomOption value={inputValue} values={values} />
            {values.map((value) => (
              <AmountSelectOption key={value} value={value} />
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default AmountInput;
