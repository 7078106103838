import React, { useState } from "react";
import { Bank } from "../../../api/types";
import { UpdateFiltersFn } from "../types";
import BankItem from "./BankItem";
import { classNames } from "../../utils";
import ShowMore from "./ShowMore";

type Props = {
  banks: Bank[];
  selectedBanksIds: string[];
  updateFilters: UpdateFiltersFn;
};

const BanksFilter = ({ banks, selectedBanksIds, updateFilters }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const toggleBank = (bank: Bank) => {
    if (selectedBanksIds.includes(bank.uid)) {
      updateFilters({ banksIds: selectedBanksIds.filter(id => id !== bank.uid) })
    } else {
      updateFilters({ banksIds: [...selectedBanksIds, bank.uid] });
    }
  }

  return (
    <div className="flex justify-between">
      <div className={classNames(
        "flex flex-wrap justify-center overflow-hidden md:justify-start",
        expanded ? "max-h-auto" : "max-h-12"
      )}>
        {banks.map(bank => (
          <BankItem
            key={bank.uid}
            bank={bank}
            selected={selectedBanksIds.includes(bank.uid)}
            onClick={toggleBank} />
        ))}
      </div>
      <div className="flex h-10 w-10 m-1">
        <ShowMore expanded={expanded} onClick={() => setExpanded(!expanded)} />
      </div>
    </div>
  );
};

export default BanksFilter;
