import React from "react";
import { UserDeposit } from "../../../api/types";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useToggle } from "react-use";

type Props = {
  userDeposit: UserDeposit;
  onEdit: (userDeposit: UserDeposit) => void;
  onClose: (userDeposit: UserDeposit) => void;
  onDelete: (userDeposit: UserDeposit) => void;
};

const DepositActions = ({ userDeposit, onEdit, onClose, onDelete }: Props) => {
  const [deleteConfirmationModalVisible, toggleDeleteConfirmationModal] = useToggle(false);
  return (
    <div className="py-2 space-x-2">
      <button
        type="button"
        className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm p-1.5 sm:px-2.5 text-center inline-flex items-center"
        onClick={() => onEdit(userDeposit)}>
        Edytuj
      </button>
      {userDeposit.status === "ACTIVE" && (
        <button
          type="button"
          className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm p-1.5 sm:px-2.5 text-center inline-flex items-center"
          onClick={() => onClose(userDeposit)}>
          Oznacz jako zamkniętą
        </button>
      )}
      <button
        type="button"
        className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm p-1.5 sm:px-2.5 text-center inline-flex items-center"
        onClick={() => toggleDeleteConfirmationModal()}>
        Usuń
      </button>
      {deleteConfirmationModalVisible && (
        <DeleteConfirmationModal
          onConfirm={() => {
            onDelete(userDeposit);
            toggleDeleteConfirmationModal();
          }}
          onClose={() => {
            toggleDeleteConfirmationModal()
          }} />
      )}
    </div>
  );
};

export default DepositActions;
