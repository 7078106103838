import React from "react";
import BankLogo from "../bankLogo/BankLogo";
import { formatPercentage } from "../utils";
import { Bank, Deposit } from "../../api/types";
import { useWatch } from "react-hook-form";
import AmountField from "./AmountField";
import NameField from "./NameField";
import StartDateField from "./StartDateField";
import EndDateField from "./EndDateField";
import AnnualPercentageRateField from "./AnnualPercentageRateField";
import BankField from "./BankField";
import LoadingBox from "../loading/LoadingBox";

export type Values = {
  deposit: Deposit | null;
  name: string;
  amount: number;
  start: string;
  end: string;
  annualPercentageRate: number;
  bank: Bank | null;
}

type Props = {
  loading?: boolean;
  deposit: Deposit | null | undefined;
  banks: Bank[];
};

const FormFields = ({ loading, deposit, banks }: Props) => {
  const bank = useWatch({ name: "bank" });

  return (
    <LoadingBox description="Wczytywanie lokat" loading={loading ?? false}>
      <div className="flex flex-1 flex-col p-4 space-y-4 sm:flex-row sm:space-y-0">
        <div className="flex flex-1 flex-col space-y-2">
          <NameField />
          <AmountField min={deposit?.minAmount} max={deposit?.maxAmount} />
          <StartDateField />
          <EndDateField />
          <AnnualPercentageRateField />
        </div>
        <div className="flex flex-1 flex-col">
          <h4 className="text-gray-700">Oferta banku</h4>

          {deposit ? (
            <div className="flex items-center">
              <div className="min-w-1/3 w-1/3 m-1 sm:min-w-1/6 sm:w-1/6">
                <BankLogo bank={bank} />
              </div>
              <div className="flex">
                {deposit.productName}
              </div>
              <div className="ml-1">
                {formatPercentage(deposit.annualPercentageRate)}
                <span className="ml-0.5 text-sm">%</span>
              </div>
            </div>
          ) : (
            <div>
              <div className="py-2 text-gray-500">Ta lokata nie ma zapisanej oferty banku</div>
              <BankField banks={banks} />
            </div>
          )}
        </div>
      </div>
    </LoadingBox>
  );
};

export default FormFields;
