import React from "react";
import InformationForNotLoggedInUsers from "./MarketingInformation";
import UserSavedDeposits from "./UserSavedDeposits";
import { useAuth0 } from "@auth0/auth0-react";

const SavedDeposits = () => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return (<InformationForNotLoggedInUsers />)
  }

  return (
    <UserSavedDeposits />
  );
};

export default SavedDeposits;
