import React from "react";
import { InterestCompounding } from "../../api/types";

type Props = {
  value: InterestCompounding
};

const InterestCompoundingText = ({ value }: Props) => {
  switch (value) {
  case "DAILY":
    return <>dzienne</>;
  case "MONTHLY":
    return <>miesięczne</>;
  case "YEARLY":
    return <>rocznie</>;
  case "ON_END":
    return <>na koniec lokaty</>;
  }
};

export default InterestCompoundingText;
