import React, { PropsWithChildren } from "react";
import { AuthorizationParams, useAuth0 } from "@auth0/auth0-react";
import { AuthAppState } from "../auth/types";
import { useLocation } from "react-router-dom";
import useAnalyticsTracker from "../analytics/useAnalyticsTracker";

type Props = {
  className?: string;
  display?: "page" | "popup" | "touch"
  screenHint?: AuthorizationParams["screen_hint"]
};

const LoginButton = ({ children, className, display, screenHint }: PropsWithChildren<Props>) => {
  const { loginClicked } = useAnalyticsTracker();
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    loginClicked(location.pathname);

    const appState: AuthAppState = {
      returnTo: location.pathname
    };

    await loginWithRedirect({
      authorizationParams: {
        display: display || "page",
        screen_hint: screenHint
      },
      appState: appState,
    });
  };

  return (
    <button className={className} onClick={handleLogin}>
      {children}
    </button>
  );
};

export default LoginButton;
