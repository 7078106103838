import React from "react";

type Props = {
  text: string;
  actions?: React.ReactNode;
};

const SectionHeader = ({ text, actions }: Props) => (
  <header className="flex items-center px-2 py-4">
    <h2 className="font-medium leading-6 text-gray-900 flex-1 px-2">
      {text}
    </h2>
    <div>
      {actions}
    </div>
  </header>

);

export default SectionHeader;
