import React from "react";
import Tooltip from "../tooltip/Tooltip";

const NetInterestTooltip = () => (
  <Tooltip>
    Zysk <strong>netto</strong> liczony uwzględniając rzeczywistą liczbę dni kalendarzowych trwania lokaty, w stosunku do 365 dni w roku. Kwota
    jest pomiejszona o podatek od dochodów kapitałowych.
  </Tooltip>
);

export default NetInterestTooltip;
