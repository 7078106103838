import React, { PropsWithChildren, ReactNode } from "react";

type Props = {
  icon: ReactNode;
}

const PropertyWithIcon = ({ icon, children }: PropsWithChildren<Props>) => (
  <div className="flex justify-start space-x-2 whitespace-nowrap text-center py-1 sm:px-2">
    <div>
      {icon}
    </div>
    <div>
      {children}
    </div>
  </div>
);

export default PropertyWithIcon;
