import React from "react";
import useScrollToTop from "../components/useScrollToTop/useScrollToTop";

const PrivacyPolicy = () => {
  useScrollToTop();
  return (
    <section className="bg-white text-justify" id="features">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">

        <h2 className="mb-4 text-4xl tracking-tight font-extrabold">
          Polityka Prywatności
        </h2>
        <p>
          Właścicielem strony internetowej fintee.pl jest firma LF LABS PROSTA SPÓŁKA AKCYJNA (KRS 0000966355) z siedzibą we Wrocławiu (50-224), Plac
          Strzelecki 25, która jest administratorem Twoich danych osobowych (dalej jako: „Administrator”).
          Niniejsza Polityka prywatności określa, w jaki sposób przetwarzamy informacje gromadzone na stronie fintee.pl, a także wyjaśnia powody, dla
          których musimy gromadzić określone dane o użytkowniku. Dlatego przed rozpoczęciem korzystania ze strony fintee.pl zapoznaj się z niniejszą
          Polityką Prywatności.
        </p>
        <p className="mt-3">
          Dbamy o Twoje dane osobowe i zobowiązujemy się do zapewnienia ich poufności i ochrony.
        </p>

        <h3 className="my-4 text-2xl font-extrabold">Gromadzone przez nas dane osobowe</h3>
        <p>
          Kiedy wchodzisz na stronę fintee.pl, automatycznie zbieramy pewne informacje o Twoim urządzeniu, w tym informacje o Twojej przeglądarce
          internetowej, adresie IP, strefie czasowej oraz niektórych plikach cookie zainstalowanych na Twoim urządzeniu. Ponadto podczas przeglądania
          strony zbieramy informacje o poszczególnych stronach internetowych lub produktach, które przeglądasz, jakie strony lub frazy wprowadzone w
          wyszukiwarce skierowały Cię na naszą Stronę oraz o tym, w jaki sposób z niej korzystasz. Te informacje zbierane automatycznie określamy
          mianem
          "Informacji o urządzeniu". Ponadto, możemy gromadzić dane osobowe, które nam przekażesz (w tym, między innymi, imię, nazwisko, adres,
          informacje o płatnościach itp.) na etapie rejestracji, aby umożliwić realizację umowy.
        </p>

        <h3 className="my-4 text-2xl font-extrabold">Dlaczego przetwarzamy Twoje dane?</h3>

        <p>
          <p>
            Naszym nadrzędnym priorytetem jest zapewnienie bezpieczeństwa danych klientów / użytkowników, dlatego też możemy przetwarzać jedynie
            ograniczone dane, tylko w takim zakresie, w jakim jest to absolutnie konieczne do utrzymania funkcjonowania Strony. Informacje zbierane
            automatycznie są wykorzystywane wyłącznie do identyfikacji potencjalnych przypadków nadużyć oraz do sporządzania danych statystycznych
            dotyczących korzystania z naszej Strony internetowej. Wspomniane dane statystyczne nie są gromadzone w sposób umożliwiający identyfikację
            konkretnego użytkownika systemu.
          </p>
          <p>
            Możesz odwiedzić naszą Stronę internetową bez konieczności ujawniania nam swojej tożsamości lub przekazywania jakichkolwiek informacji, na
            podstawie których ktoś mógłby rozpoznać Cię jako konkretną, możliwą do zidentyfikowania osobę. Jeśli jednak zechcesz skorzystać z
            niektórych
            funkcji Strony, lub chcesz otrzymywać nasz newsletter, lub podać inne informacje poprzez wypełnienie formularza, możesz przekazać nam
            swoje
            dane osobowe, takie jak adres e-mail, imię, nazwisko, miasto w którym mieszkasz, firma, organizacja, numer telefonu. Możesz zrezygnować z
            podawania nam swoich danych osobowych, jednak wówczas możesz nie być w stanie korzystać z niektórych funkcji Strony. Na przykład, nie
            będziemy
            w stanie wysyłać Ci naszego Newslettera lub nawiązać z Tobą kontaktu bezpośrednio na Stronie. Użytkownicy, którzy nie są pewni, które
            informacje są obowiązkowe, mogą skontaktować się z nami pisząc na adres <a href="mailto:kontakt@fintee.pl">kontakt@fintee.pl</a>.
          </p>
        </p>

        <h3 className="my-4 text-2xl font-extrabold">Okres przetwarzania danych osobowych</h3>

        <p>
          <p>
            Przetwarzamy Twoje dane osobowe jedynie przez okres niezbędny do spełnienia celu ich przechowywania lub dopóki jest to od nas wymagane
            zgodnie z prawem, w szczególności do momentu przedawnienia ewentualnych roszczeń lub wygaśnięcia obowiązku archiwizacji wynikającego z
            przepisów prawa.
          </p>
          <p>
            W przypadku przetwarzania danych osobowych w celu świadczenia usługi newsletter, Twoje dane osobowe będą przetwarzane przez okres
            niezbędny do
            świadczenia tej usługi, do czasu Twojej rezygnacji z tej usługi.
          </p>
          <p>
            W razie przetwarzania danych osobowych w oparciu o wyrażoną przez Ciebie zgodę, Twoje dane będą przechowywane do momentu jej wycofania. W
            każdej chwili istnieje możliwość wycofania wyrażonych zgód. Odwołanie zgody na przetwarzanie nie będzie miało wpływu na zgodność z prawem
            przetwarzania, którego dokonano przed jej wycofaniem.
          </p>
          <p>
            W przypadku przetwarzania Twoich danych na podstawie prawnie uzasadnionego interesu administratora, Administrator przestanie przetwarzać
            dane
            jeżeli zgłosisz skutecznie sprzeciw wobec przetwarzania danych.
          </p>
        </p>

        <h3 className="my-4 text-2xl font-extrabold">Twoje prawa</h3>

        <p>
          Przysługują Ci następujące prawa związane z Twoimi danymi osobowymi:

          <ul className="m-8" style={{ listStyleType: "disc" }}>
            <li>Prawo do uzyskania informacji.</li>
            <li>Prawo dostępu do informacji.</li>
            <li>Prawo do korekty danych.</li>
            <li>Prawo do usunięcia danych.</li>
            <li>Prawo do ograniczenia przetwarzania danych.</li>
            <li>Prawo do zastrzeżenia sposobu przetwarzania danych.</li>
            <li>Prawo sprzeciwu.</li>
            <li>Prawo do przenoszenia danych (o ile istnieje możliwość techniczna).</li>
            <li>Prawa odnoszące się do automatyzacji podejmowania decyzji i profilowania.</li>
          </ul>
          <p className="mb-3">
            Jeśli chcesz skorzystać z tego prawa, skontaktuj się z nami za pośrednictwem danych kontaktowych podanych poniżej.
          </p>
          <p className="mt-3">
            Bezpieczeństwo danych osobowych jest dla nas priorytetem, jeśli uznasz jednak, że przetwarzając Twoje dane osobowe naruszamy
            przepisy RODO, masz prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
          </p>

          <h3 className="my-4 text-2xl font-extrabold">Linki do innych stron</h3>

          <p>
            Na naszej Stronie internetowej mogą znajdować się linki do innych witryn, które nie stanowią naszej własności ani nie są przez nas
            nadzorowane. Informujemy, że nie ponosimy odpowiedzialności za inne strony internetowe ani za ich praktyki związane z ochroną prywatności.
            Z
            tego powodu zachęcamy do świadomego opuszczenia naszej strony i zapoznania się z polityką prywatności każdej strony internetowej, która
            może
            gromadzić dane osobowe.
          </p>

          <h3 className="my-4 text-2xl font-extrabold">Ochrona danych</h3>

          <p>
            Zapewniamy bezpieczeństwo informacji udostępnianych przez użytkownika na serwerach komputerowych w środowisku kontrolowanym, bezpiecznym,
            zabezpieczonym przed nieuprawnionym dostępem, wykorzystaniem lub ujawnieniem. W ramach kontroli i nadzoru stosujemy odpowiednie
            zabezpieczenia administracyjne, techniczne i fizyczne w celu ochrony przed nieuprawnionym dostępem, wykorzystaniem, modyfikacją i
            ujawnieniem danych osobowych. Nie możemy jednak zagwarantować bezpieczeństwa transmisji danych przez sieć internetową lub bezprzewodową.
          </p>

          <h3 className="my-4 text-2xl font-extrabold">Ujawnienie informacji wynikających z przepisów prawa</h3>
          <p>
            Wszelkie informacje, które gromadzimy, wykorzystujemy lub otrzymujemy, zostaną ujawnione, jeśli jest to wymagane lub zgodne z prawem, np.
            w
            celu wywiązania się z wezwania do sądu lub podobnego postępowania prawnego oraz wówczas, gdy w dobrej wierze uznamy, że ujawnienie jest
            koniecznie w celu ochrony naszych praw, Twojego bezpieczeństwa lub bezpieczeństwa innych osób, wykrycia oszustwa lub w odpowiedzi na
            wezwanie organów rządowych.
          </p>

          <h3 className="my-4 text-2xl font-extrabold">Zmiany w Polityce prywatności</h3>
          <p>
            W celu zapewnienia, iż nasza polityka prywatności spełnia przez cały czas aktualne wymogi nałożone w przepisach prawa, zastrzegamy sobie
            prawo do wnoszenia do niej zmian w dowolnym czasie. Powyższe ma również zastosowanie w przypadkach, gdy polityka prywatności wymaga zmian
            w
            celu objęcia nią nowych lub zmienionych produktów lub usług na naszej stronie.
          </p>

          <h3 className="my-4 text-2xl font-extrabold">Informacje kontaktowe</h3>

          <p>
            Jeżeli chcesz się z nami skontaktować, aby uzyskać więcej informacji na temat niniejszej Polityki Prywatności lub w jakiejkolwiek sprawie
            mającej związek z Twoimi prawami i danymi osobowymi, możesz wysłać wiadomość e-mail na adres <a
              href="mailto:kontakt@fintee.pl">kontakt@fintee.pl</a> lub
            pocztą tradycyjną pod adres: LF Labs, Plac Strzelecki 25, 50-224 Wrocław.
          </p>

        </p>

      </div>
    </section>
  );
};

export default PrivacyPolicy;
