import React from "react";
import { useRouteError } from "react-router-dom";
import ErrorSection from "../components/errorHandler/ErrorSection";

const ErrorPage = () => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <ErrorSection message={error.statusText || error.message} />
  );
};

export default ErrorPage;
