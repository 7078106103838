import React from "react";
import { useController } from "react-hook-form";
import AmountInput from "../inputs/AmountInput";
import FormItem from "./FormItem";
import { formatAmount } from "../utils";

type Props = {
  min: number | undefined | null;
  max: number | undefined | null;
}

const AmountField = ({ min, max }: Props) => {
  const computedMin = min ?? 1;
  const { field } = useController({
    name: "amount",
    rules: {
      required: "Kwota jest wymagana",
      min: { value: computedMin, message: `Kwota musi być większa lub równa ${formatAmount(computedMin)}` },
      max: max ? { value: max, message: `Kwota musi być mniejsza lub równa ${formatAmount(max)}` } : undefined
    }
  })

  return (
    <FormItem
      htmlFor="user-deposit-amount"
      label="Kwota"
      name="amount"
      content={(
        <AmountInput
          id="user-deposit-amount"
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  );
};

export default AmountField;
