import React, { Fragment } from "react";
import formatISO from "date-fns/formatISO";
import { Bank, Deposit } from "../../../api/types";
import { Dialog, Transition } from "@headlessui/react";
import Actions from "./Actions";
import BankLogo from "../../bankLogo/BankLogo";
import AccountRecommendations from "./AccountRecommendations";
import { FormProvider, useForm } from "react-hook-form";
import DepositParameters, { Values } from "./DepositParameters";
import DepositDetails from "./DepositDetails";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthAppState } from "../../auth/types";
import { paths } from "../../../routes/links";
import { useNavigate } from "react-router-dom";
import useAnalyticsTracker from "../../analytics/useAnalyticsTracker";

type Props = {
  deposit: Deposit;
  selectedAmount: number;
  banks: Bank[];
  onClose: () => void;
};

const SelectedDepositModal = ({ deposit, selectedAmount, banks, onClose }: Props) => {
  const { savingDepositRequested } = useAnalyticsTracker();
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const formMethods = useForm<Values>({
    defaultValues: {
      amount: selectedAmount,
      startDate: formatISO(new Date(), { representation: "date" })
    }
  })
  const bank = banks.find(b => b.uid === deposit.bankId) ?? null;

  const onSubmit = (values: Values) => {
    const appState: AuthAppState = {
      action: {
        type: "SAVE_DEPOSIT",
        depositId: deposit.uid,
        amount: values.amount,
        startDate: values.startDate
      }
    };

    savingDepositRequested(isAuthenticated)

    if (isAuthenticated) {
      navigate(paths.savedDeposits, {
        state: {
          action: appState.action
        }
      });
    } else {
      return loginWithRedirect({
        authorizationParams: {
          display: "page",
        },
        appState,
      });
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Transition appear show as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className="w-full max-w-4xl transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="div"
                      className="text-lg font-medium leading-6 text-gray-900 p-4 flex align-middle items-center"
                    >
                      <div className="w-1/3 sm:w-28 h-16">
                        <BankLogo bank={bank} />
                      </div>
                      <h3 className="ml-3">{deposit.productName}</h3>
                    </Dialog.Title>

                    <div className="px-4">
                      <DepositDetails deposit={deposit} />

                      <DepositParameters deposit={deposit} />
                    </div>

                    {!isAuthenticated && (
                      <AccountRecommendations />
                    )}

                    <Actions deposit={deposit} onClose={onClose} />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </form>
        </Dialog>
      </Transition>
    </FormProvider>
  );
};

export default SelectedDepositModal;
