import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";

type Props = {
  htmlFor?: string;
  label: string;
  name?: string;
  content: React.ReactNode;
};

const ParameterItem = ({ htmlFor, label, name, content }: Props) => {
  const { formState: { errors } } = useFormContext();

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-1 flex-col">
        <label className="text-gray-700" htmlFor={htmlFor}>
          {label}
        </label>
        <div className="flex flex-1">
          {content}
        </div>
      </div>
      {name && (
        <div>
          <ErrorMessage
            name={name}
            errors={errors}
            render={({ message }) => message && (
              <div className="py-2">
                <p className="flex items-center text-red-700">
                  <ExclamationCircleIcon className="h-4 w-4 mr-1" />
                  {message}
                </p>
              </div>
            )} />
        </div>
      )}
    </div>
  );
};

export default ParameterItem;
