import React from "react";
import { useToggle } from "react-use";
import DepositsList from "./list/DepositsList";
import { Bank, UserDeposit } from "../../api/types";
import useAnalyticsTracker from "../analytics/useAnalyticsTracker";

type Props = {
  closedDeposits: UserDeposit[];
  banks: Bank[];
  onUserDepositEdit: (userDeposit: UserDeposit) => void;
  onUserDepositClose: (userDeposit: UserDeposit) => void;
  onUserDepositDelete: (userDeposit: UserDeposit) => void;
};

const ClosedDepositsList = ({ closedDeposits, banks, onUserDepositEdit, onUserDepositDelete }: Props) => {
  const { closedDepositsToggled } = useAnalyticsTracker();
  const [on, toggle] = useToggle(false);

  const onToggle = () => {
    toggle();
    closedDepositsToggled();
  }

  return (
    <div className="flex flex-col">
      <div className="flex justify-end py-4">
        <button onClick={onToggle} className="text-sm text-primary underline p-2">{on ? "Ukryj zamknięte lokaty" : "Pokaż zamknięte lokaty"}</button>
      </div>

      {on && (
        <DepositsList
          deposits={closedDeposits}
          banks={banks}
          onUserDepositEdit={onUserDepositEdit}
          onUserDepositClose={onUserDepositEdit}
          onUserDepositDelete={onUserDepositDelete} />
      )}
    </div>
  );
};

export default ClosedDepositsList;
