import React from "react";
import MainSection from "../../components/savedDeposits/info/MainSection";
import FeaturesSection from "../../components/savedDeposits/info/FeaturesSection";
import NewsletterSection from "../../components/savedDeposits/info/NewsletterSection";
import DepositLinksSection from "../../components/savedDeposits/info/DepositLinksSection";

const MarketingInformation = () => (
  <>
    <MainSection />
    <FeaturesSection />
    <DepositLinksSection />
    <NewsletterSection />
  </>
);

export default MarketingInformation;
