import React from "react";
import { Deposit } from "../../../api/types";
import DetailsItem from "./DetailsItem";
import AttachmentsSection from "./AttachmentsSection";
import AdditionalInfoSection from "./AdditionalInfoSection";
import Separator from "./Separator";
import LimitsItem from "./LimitsItem";
import CurrentAccountItem from "./CurrentAccountItem";
import AmountItem from "./AmountItem";

type Props = {
  deposit: Deposit;
};

const DepositDetails = ({ deposit }: Props) => (
  <div className="p-2 sm:p-0">
    <div className="flex flex-1 flex-col md:flex-row md:space-x-2">
      <div className="flex flex-1 flex-col">
        <CurrentAccountItem deposit={deposit} />
        <DetailsItem
          label={"Klient"}
          content={deposit.onlyForNewCustomers ? (
            <>Tylko dla nowych klientów</>
          ) : (
            <>Każdy</>
          )}
        />
        <DetailsItem
          label={"Środki"}
          content={deposit.onlyForNewFunds ? (
            <div>
              <div>
                Tylko dla nowych środków.
              </div>
              <div>
                {deposit.newFundsAdditionalInfo}
              </div>
            </div>
          ) : (
            <>Dowolne środki</>
          )}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <AmountItem deposit={deposit} />
        <LimitsItem deposit={deposit} />
        <DetailsItem
          label={"Gwarancje bankowe"}
          content={(<>{deposit.bankGuarantees}</>)}
        />
      </div>
    </div>

    <Separator>
      <AdditionalInfoSection deposit={deposit} />
      <AttachmentsSection attachments={deposit.attachments} />
    </Separator>
  </div>
);

export default DepositDetails;
