import React from "react";
import { Deposit } from "../../../api/types";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  deposit: Deposit;
  onClose: () => void;
};

const Actions = ({ deposit, onClose }: Props) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      <a
        href={deposit.url}
        className="text-primary hover:underline focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center cursor-pointer sm:hidden"
        target="_blank"
        rel="external nofollow noreferrer">
        <ArrowTopRightOnSquareIcon className="w-4 pr-1" />
        Przejdź do oferty banku
      </a>

      <div className="flex justify-between space-x-2 p-4">
        <button
          type="button"
          className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          onClick={onClose}>
          Zamknij
        </button>
        <div className="flex space-x-2">
          <a
            href={deposit.url}
            className="text-primary hover:underline focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center cursor-pointer hidden sm:inline-flex"
            target="_blank"
            rel="external nofollow noreferrer">
            <ArrowTopRightOnSquareIcon className="w-4 pr-1" />
            Przejdź do oferty banku
          </a>

          {isAuthenticated ? (
            <button
              type="submit"
              className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer">
              Zapisz lokatę
            </button>
          ) : (
            <button
              type="submit"
              className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer">
              Zaloguj się i zapisz lokatę
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Actions;
