import React from "react";
import { Deposit } from "../../../api/types";
import AdditionalInfoList from "../details/AdditionalInfoList";
import AttachmentsList from "../details/AttachmentsList";
import DepositChecklist from "./DepositChecklist";

type Props = {
  deposit: Deposit;
};

const DepositDetails = ({ deposit }: Props) => (
  <div className="space-y-2">
    <DepositChecklist deposit={deposit} />

    <div className="flex flex-1 flex-col sm:flex-row sm:space-x-3">
      <div className="flex flex-1 flex-col">
        <h4 className="text-lg">Dodatkowe informacje</h4>
        <AdditionalInfoList deposit={deposit} />
      </div>

      {deposit.attachments.length > 0 && (
        <div className="flex flex-1 flex-col">
          <h4 className="text-lg">Dokumenty</h4>
          <AttachmentsList attachments={deposit.attachments} />
        </div>
      )}
    </div>
  </div>
);

export default DepositDetails;
