import React from "react";

type Props = {
  header: string;
  value: string;
};

const SummaryBox = ({header, value}: Props) => (
  <div className="border border-secondary rounded-lg bg-white flex-1 p-2">
    <div className="text-sm">{header}</div>
    <div className="text-xl">{value}</div>
  </div>
);

export default SummaryBox;
