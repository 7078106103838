import React, { useEffect, useState } from "react";
import Summary from "../../components/savedDeposits/Summary";
import DepositsSection from "../../components/savedDeposits/DepositsSection";
import useUserDeposits from "../../components/savedDeposits/useUserDeposits";
import useBanks from "../../components/deposit/useBanks";
import AddUserDepositModal from "../../components/userDepositModal/AddUserDepositModal";
import { Values } from "../../components/userDepositModal/FormFields";
import AddSelectedUserDepositModal from "../../components/userDepositModal/AddSelectedUserDepositModal";
import { UserDeposit } from "../../api/types";
import useAnalyticsTracker from "../../components/analytics/useAnalyticsTracker";

const UserSavedDeposits = () => {
  const { savedDepositsDisplayed, userDepositAdded, userDepositUpdated, userDepositClosed } = useAnalyticsTracker();
  const banks = useBanks();
  const {
    status,
    deposits,
    closedUserDeposits,
    addUserDeposit,
    updateUserDeposit,
    deleteUserDeposit,
  } = useUserDeposits();
  const [addDepositModalVisible, setAddDepositModalVisible] = useState(false);

  const onAddDeposit = async (values: Values) => {
    await addUserDeposit({
      name: values.name,
      start: values.start,
      end: values.end,
      amount: values.amount,
      currency: "PLN",
      annualPercentageRate: values.annualPercentageRate,
      bank: values.bank,
      status: "ACTIVE",
      deposit: values.deposit
    })
    setAddDepositModalVisible(false);

    userDepositAdded();
  }

  const onUpdateUserDeposit = async (userDeposit: Partial<UserDeposit>) => {
    await updateUserDeposit(userDeposit);
    userDepositUpdated();
  }

  const closeUserDeposit = async (userDeposit: UserDeposit) => {
    await updateUserDeposit({ ...userDeposit, status: "CLOSED" });
    userDepositClosed();
  }

  useEffect(() => {
    savedDepositsDisplayed()
  }, [savedDepositsDisplayed]);

  return (
    <main>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <Summary deposits={deposits} />
        <DepositsSection
          status={status}
          banks={banks}
          userDeposits={deposits}
          closedUserDeposits={closedUserDeposits}
          onAddDeposit={() => setAddDepositModalVisible(true)}
          updateUserDeposit={onUpdateUserDeposit}
          closeUserDeposit={closeUserDeposit}
          deleteUserDeposit={deleteUserDeposit}
        />

        {addDepositModalVisible && (
          <AddUserDepositModal
            banks={banks}
            onSave={onAddDeposit}
            onClose={() => setAddDepositModalVisible(false)} />
        )}

        <AddSelectedUserDepositModal banks={banks} onSave={onAddDeposit} />
      </div>
    </main>
  );
};

export default UserSavedDeposits;
