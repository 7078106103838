import { Filters } from "./types";
import { useLocationBasedStorage } from "./filtersStorage";

const DEFAULTS: Filters = {
  amount: 1_000,
  duration: { min: 30, max: 270 },
  banksIds: [],
  channels: [],
  exclusions: {
    currentAccountRequired: false,
    onlyForNewCustomers: false,
    onlyForNewFunds: false
  }
}

const useFilters = () => {
  const { load, save } = useLocationBasedStorage(DEFAULTS);

  const filters = load();

  return {
    filters,
    updateFilters: (update: Partial<Filters>) => {
      save({ ...filters, ...update })
    }
  }
}

export default useFilters;
