import React from "react";
import { Bank, UserDeposit } from "../../api/types";
import UserDepositModalContainer from "./UserDepositModalContainer";
import FormFields, { Values } from "./FormFields";

type Props = {
  userDeposit: UserDeposit;
  banks: Bank[];
  onSave: (userDeposit: UserDeposit) => Promise<any>;
  onClose: () => void;
};

const EditUserDepositModal = ({ userDeposit, banks, onSave, onClose }: Props) => {
  const onSubmit = async (values: Values) => {
    return onSave({ ...userDeposit, ...values });
  }

  return (
    <UserDepositModalContainer
      title={userDeposit.name}
      defaultValues={{
        deposit: userDeposit.deposit ?? null,
        name: userDeposit.name,
        amount: userDeposit.amount,
        start: userDeposit.start,
        end: userDeposit.end,
        annualPercentageRate: userDeposit.annualPercentageRate,
        bank: userDeposit.bank ?? null
      }}
      onSubmit={onSubmit}
      onClose={onClose}>
      <FormFields
        banks={banks}
        deposit={userDeposit.deposit} />
    </UserDepositModalContainer>
  );
};

export default EditUserDepositModal;
