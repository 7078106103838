import React from "react";
import RecommendationItem from "./RecommendationItem";
import { BellAlertIcon, ChartBarIcon, TableCellsIcon } from "@heroicons/react/24/solid";
import Logo from "../../logo/Logo";

const AccountRecommendations = () => (
  <div className="flex flex-col space-y-2 m-4 p-4 border rounded bg-light">
    <h4 className="text-lg text-center sm:text-left">
      <span>Zobacz co zyskujesz z kontem </span>
      <Logo height="18" className="align-baseline -my-0.5" />
    </h4>

    <div className="flex flex-col space-y-1 sm:flex-row">
      <RecommendationItem
        icon={BellAlertIcon}
        header="Przypomnienia"
        text="Nie przegapisz końca lokaty" />
      <RecommendationItem
        icon={TableCellsIcon}
        header="Oszczędności w jednym miejscu"
        text="Monitoruj wszystkie lokaty" />
      <RecommendationItem
        icon={ChartBarIcon}
        header="Powiadomienia o większym zysku"
        text="Oszczędzaj szybciej na lepszej lokacie" />
    </div>
  </div>
);

export default AccountRecommendations;
