import React from "react";
import DetailsItem from "./DetailsItem";
import { Deposit } from "../../../api/types";
import { formatAmount } from "../../utils";

type Props = {
  deposit: Deposit;
};

function formatDepositsText(number: number) {
  switch (number) {
  case 1:
    return "lokata"
  case 2:
  case 3:
  case 4:
    return "lokaty"
  default:
    return "lokat"
  }
}

const Separator = () => (
  <span className="p-2"> / </span>
)

const MaxNumberOfDeposits = ({ value }: { value: number }) => (
  <>
    {value}
    &nbsp;
    <small>{formatDepositsText(value)}</small>
  </>
);

const InvestmentsSumLimit = ({ value }: { value: number }) => (
  <>{formatAmount(value)} <small>na lokatach</small></>
);

const Content = ({ deposit }: { deposit: Deposit }) => {
  if (!deposit.maxNumberOfDepositsPerCustomer && !deposit.investmentsSumLimit) {
    return (<>Brak</>)
  }

  if (deposit.maxNumberOfDepositsPerCustomer && deposit.investmentsSumLimit) {
    return (
      <>
        <MaxNumberOfDeposits value={deposit.maxNumberOfDepositsPerCustomer} />
        <Separator />
        <InvestmentsSumLimit value={deposit.investmentsSumLimit} />
      </>
    )
  }

  if (deposit.maxNumberOfDepositsPerCustomer) {
    return (<MaxNumberOfDeposits value={deposit.maxNumberOfDepositsPerCustomer} />)
  } else {
    return (<InvestmentsSumLimit value={deposit.investmentsSumLimit!} />)
  }
}

const LimitsItem = ({ deposit }: Props) => (
  <DetailsItem
    label={"Limity"}
    content={(
      <Content deposit={deposit} />
    )}
  />
);

export default LimitsItem;
