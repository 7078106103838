import React, { useEffect, useState } from "react";
import DurationSlider from "./DurationSlider";
import durationFormatter from "./durationFormatter";
import { Duration as DurationType } from "../types";

type Props = {
  value: DurationType;
  onChange: (duration: DurationType) => void;
};

const DurationFilter = ({ value, onChange }: Props) => {
  const [localValue, setLocalValue] = useState(value);
  const displayValue = formatValue(localValue);

  useEffect(() => {
    setLocalValue(value);
  }, [value])

  return (
    <div>
      <label className="text-gray-700">Okres: {displayValue}</label>
      <DurationSlider
        onChange={(duration) => {
          setLocalValue(duration);
        }}
        onAfterChange={(duration) => {
          onChange(duration);
        }}
        value={localValue} />
    </div>
  );
};

export default DurationFilter;

function formatValue(value: DurationType) {
  if (value.min === value.max) {
    return durationFormatter(value.min);
  }
  return `${durationFormatter(value.min)} – ${durationFormatter(value.max)}`
}
