import React, { ReactNode } from "react";

type Props = {
  label: ReactNode
  content: ReactNode
};

const DetailsItem = ({ label, content }: Props) => (
  <div className="flex flex-1 flex-col pt-2">
    <div className="text-sm text-gray-400">{label}</div>
    <div className="first-letter:capitalize">{content}</div>
  </div>
);

export default DetailsItem;
