import React from "react";
import { useFormContext } from "react-hook-form";
import { isBefore, parseISO } from "date-fns";
import DateInput from "../../inputs/DateInput";
import ParameterItem from "./ParameterItem";

const StartDateField = () => {
  const { register } = useFormContext();

  return (
    <ParameterItem
      htmlFor="deposit-parameters-start-date"
      label="Data rozpoczęcia"
      name="startDate"
      content={(
        <DateInput
          id="deposit-parameters-start-date"
          {...register("startDate", {
            required: "Data rozpoczęcia jest wymagana",
            validate: (value, formValues) => {
              if (value && formValues.endDate && !isBefore(parseISO(value), parseISO(formValues.endDate))) {
                return "Data rozpoczęcia musi być wcześniejsza niż data zakończenia"
              }
            }
          })} />
      )} />
  );
};

export default StartDateField;
