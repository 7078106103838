import React, {PropsWithChildren, useState} from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Transition } from "@headlessui/react";
import { usePopper } from "react-popper";

type Props = {};

const Tooltip = ({ children }: PropsWithChildren<Props>) => {
  const [isShowing, setIsShowing] = useState(false);
  const [triggerElement, setTriggerElement] = useState<Element | null>(null);
  const [tooltipElement, setTooltipElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(triggerElement, tooltipElement);

  return (
    <span>
      <span ref={setTriggerElement}
        className="cursor-pointer"
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}>
        <InformationCircleIcon className="h-4 mx-1 inline" />
      </span>
      <Transition ref={setTooltipElement}
        style={styles.popper}
        {...attributes.popper}
        show={isShowing}
        enter="transition-opacity duration-800"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-250"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-sm mx-auto bg-gray-50 shadow-lg rounded-l p-2 whitespace-normal text-black">
          <small>{children}</small>
        </div>
      </Transition>
    </span>
  );
};

export default Tooltip;
