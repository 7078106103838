import React from "react";
import { Dialog } from "@headlessui/react";
import { paths } from "../../../routes/links";

type Props = {
  showConfiguration: () => void;
  acceptAll: () => void;
};

const CookiesInformation = ({ showConfiguration, acceptAll }: Props) => (
  <>
    <Dialog.Title
      as="h3"
      className="text-lg font-medium leading-6 text-gray-900 p-4"
    >
      Dbamy o Twoją prywatność
    </Dialog.Title>

    <div className="p-4">
      <p>
        W naszym serwisie internetowym, wraz z naszymi Partnerami, używamy plików cookies. Niektóre z nich są konieczne do prawidłowego
        funkcjonowania serwisu, a inne na które możesz wyrazić zgodę zostały opisane poniżej:
      </p>

      <ul className="list-inside list-disc py-4">
        <li>pliki cookies wydajności i personalizacji - umożliwiające zliczanie wizyt, ustalanie zachowań użytkowników i pozwalające na
          lepszą personalizację prezentowanych treści
        </li>
      </ul>

      <p>
        Dokładny opis zakresu i celów przetwarzania danych znajdziesz w naszej <a href={paths.privacyPolicy} className="text-primary hover:underline">polityce prywatności</a>
      </p>

    </div>
    <div className="flex justify-between p-4">
      <button
        type="button"
        className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        onClick={showConfiguration}>
        Ustawienia zaawansowane
      </button>
      <button
        type="button"
        className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        onClick={acceptAll}>
        Akceptuj pliki cookies 🍪
      </button>
    </div>
  </>
);

export default CookiesInformation;
