import React from "react";
import SectionHeader from "../SectionHeader";
import AddDepositButton from "./AddDepositButton";

type Props = {
  onAddDeposit: () => void;
}

const DepositsListHeader = ({ onAddDeposit }: Props) => (
  <SectionHeader
    text="Zapisane lokaty"
    actions={(
      <AddDepositButton onManualAdd={onAddDeposit} />
    )} />
);

export default DepositsListHeader;
