import React from "react";
import { Deposit } from "../../../api/types";
import ChecklistItem from "./ChecklistItem";

type Props = {
  deposit: Deposit;
};

const DepositChecklist = ({ deposit }: Props) => (
  <div className="flex flex-1 flex-col space-y-2 pb-2 sm:flex-row sm:space-x-2 sm:space-y-0">
    <ChecklistItem
      positive={!deposit.currentAccountRequired}
      positiveText="Konto niewymagane"
      negativeText="Konto wymagane" />
    <ChecklistItem
      positive={!deposit.onlyForNewCustomers}
      positiveText="Każdy"
      negativeText="Tylko dla nowych klientów" />
    <ChecklistItem
      positive={!deposit.onlyForNewFunds}
      positiveText="Dowolne środki"
      negativeText="Tylko dla nowych środków" />
  </div>
);

export default DepositChecklist;
