import React from "react";
import FormItem from "./FormItem";
import BankSelect from "../inputs/BankSelect";
import { useController } from "react-hook-form";
import { Bank } from "../../api/types";

type Props = {
  banks: Bank[];
};

const BankField = ({ banks }: Props) => {
  const { field } = useController({
    name: "bank"
  })

  return (
    <FormItem
      htmlFor="user-deposit-bank"
      label="Bank"
      name="bank"
      content={(
        <BankSelect
          id="user-deposit-bank"
          value={field.value ?? null}
          onChange={field.onChange}
          banks={banks} />
      )}
    />
  );
};

export default BankField;
