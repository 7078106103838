import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import isDeepEqual from "fast-deep-equal/react";
import { Duration, Filters } from "./types";
import queryString from "query-string";
import { SalesChannel } from "../../api/types";
import { SALES_CHANNELS } from "../../api/deposit";
import { SUPPORTED_DURATION } from "./duration/durationFormatter";

type Storage = {
  load: () => Filters;
  save: (filters: Filters) => void
};

type SearchQueryParams = {
  amount?: number;
  dMin?: number;
  dMax?: number;
  bIds?: string | string[];
  c?: string | string[];
  eAccount?: boolean;
  eNewCustomers?: boolean;
  eNewFunds?: boolean;
}

const getNumber = (
  maybeNumber: Array<string | boolean | number | null> | null | string | boolean | number,
  defaultValue: number,
  maxNumber: number
) => {
  if (maybeNumber) {
    const maybeNumberAsString = `${maybeNumber}`;
    if (maybeNumberAsString.length < 12) {
      const parsedNumber = parseInt(maybeNumberAsString, 10);
      if (Number.isFinite(parsedNumber) && parsedNumber < maxNumber) {
        return parsedNumber;
      } else {
        return maxNumber;
      }
    } else {
      return maxNumber;
    }
  }

  return defaultValue;
}

const getBoolean = (
  maybeBoolean: Array<string | boolean | number | null> | null | string | boolean | number,
  defaultValue: boolean
): boolean => {
  if (typeof maybeBoolean === "boolean") {
    return maybeBoolean as boolean;
  }

  return defaultValue;
}

const getDuration = (parsedMin: number, parsedMax: number, defaultDuration: Duration): Duration => {
  if (!SUPPORTED_DURATION.includes(parsedMin) || !SUPPORTED_DURATION.includes(parsedMax)) {
    return defaultDuration;
  }
  if (parsedMin > parsedMax) {
    return { min: parsedMax, max: parsedMin };
  } else {
    return { min: parsedMin, max: parsedMax };
  }
}

const getArrayParamOrDefault = (value: string | string[] | undefined, defaultValue: string[]): string[] => {
  if (typeof value === "string") {
    return [value];
  } else if (Array.isArray(value)) {
    return value;
  }
  return defaultValue;
}

const getIfNotDefault = <T>(value: T, defaultValue: T): T | undefined => {
  if (!isDeepEqual(value, defaultValue)) {
    return value;
  }

  return undefined;
}

const getChannels = (queryParam: string | string[] | undefined, defaultValue: SalesChannel[]): SalesChannel[] => {
  const channels = getArrayParamOrDefault(queryParam, defaultValue)
  return channels.filter(c => SALES_CHANNELS.includes(c as SalesChannel)) as SalesChannel[];
}

const locationBased = (location: Location, navigate: NavigateFunction, defaultValues: Filters): Storage => ({
  load: () => {
    const parsed = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true, arrayFormat: "comma" });

    return {
      amount: getNumber(parsed.amount, defaultValues.amount, 9_999_999_999),
      duration: getDuration(
        getNumber(parsed.dMin, defaultValues.duration.min, 1_800),
        getNumber(parsed.dMax, defaultValues.duration.max, 1_800),
        defaultValues.duration
      ),
      banksIds: getArrayParamOrDefault(parsed.bIds as (string | string[]), defaultValues.banksIds),
      channels: getChannels(parsed.c as (string | string[]), defaultValues.channels),
      exclusions: {
        currentAccountRequired: getBoolean(parsed.eAccount, defaultValues.exclusions.currentAccountRequired),
        onlyForNewCustomers: getBoolean(parsed.eNewCustomers, defaultValues.exclusions.onlyForNewCustomers),
        onlyForNewFunds: getBoolean(parsed.eNewFunds, defaultValues.exclusions.onlyForNewFunds),
      }
    }
  },
  save: (filters) => {
    const query: SearchQueryParams = {
      amount: getIfNotDefault(filters.amount, defaultValues.amount),
      dMin: getIfNotDefault(filters.duration.min, defaultValues.duration.min),
      dMax: getIfNotDefault(filters.duration.max, defaultValues.duration.max),
      bIds: getIfNotDefault(filters.banksIds, defaultValues.banksIds),
      c: getIfNotDefault(filters.channels, defaultValues.channels),
      eAccount: getIfNotDefault(filters.exclusions.currentAccountRequired, defaultValues.exclusions.currentAccountRequired),
      eNewCustomers: getIfNotDefault(filters.exclusions.onlyForNewCustomers, defaultValues.exclusions.onlyForNewCustomers),
      eNewFunds: getIfNotDefault(filters.exclusions.onlyForNewFunds, defaultValues.exclusions.onlyForNewFunds)
    }

    navigate({ search: queryString.stringify(query, { arrayFormat: "comma" }) })
  }
})

export const useLocationBasedStorage = (defaultValues: Filters): Storage => {
  const location = useLocation();
  const navigate = useNavigate();
  return locationBased(location, navigate, defaultValues);
}
