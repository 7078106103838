import React from "react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { Deposit } from "../../api/types";

type Props = {
  deposit: Deposit;
  onSelect: (deposit: Deposit) => void;
};

const SelectDepositButton = ({ deposit, onSelect }: Props) => (
  <button
    type="button"
    className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
    onClick={() => onSelect(deposit)}>
    Wybierz
    <ChevronDoubleRightIcon className="h-4 w-4 ml-2 -mr-1" />
  </button>
);

export default SelectDepositButton;
