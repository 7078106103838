import React, { PropsWithChildren, ReactNode } from "react";
import ErrorSection from "./ErrorSection";
import ReactGA from "react-ga4";

type Props = {
  fallback?: ReactNode
}

class ErrorBoundary extends React.Component<PropsWithChildren<Props>, { hasError: boolean }> {

  constructor(props: PropsWithChildren<Props>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    ReactGA.event("error", { error, info });
  }

  render() {
    if (this.state.hasError) {
      return (this.props.fallback || <ErrorSection />);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
