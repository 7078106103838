import { Deposit } from "../../api/types";
import { BuildingOfficeIcon, DevicePhoneMobileIcon, GlobeAltIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { PropsWithChildren } from "react";

type Props = {
  deposit: Deposit
};

const isAvailableInMobileAppOnly = (deposit: Deposit) => {
  return deposit.channelMobileApp && !deposit.channelOnline && !deposit.channelPhone && !deposit.channelBranch;
}

const Container = ({ children }: PropsWithChildren) => (
  <div className="flex flex-row items-center space-x-1">{children}</div>
)

export const ChannelAvailability = ({ deposit }: Props) => {
  if (isAvailableInMobileAppOnly(deposit)) {
    return (
      <Container>
        <DevicePhoneMobileIcon className="h-4" />
        <span>tylko w aplikacji</span>
      </Container>
    );
  }

  if (deposit.channelOnline) {
    return (
      <Container>
        <GlobeAltIcon className="h-4" />
        <span>dostępna online</span>
      </Container>
    );
  }

  return (
    <Container>
      {deposit.channelBranch && (<BuildingOfficeIcon className="h-4" />)}
      {deposit.channelPhone && (<PhoneIcon className="h-4" />)}
      {deposit.channelMobileApp && (<DevicePhoneMobileIcon className="h-4" />)}
    </Container>
  );

};
