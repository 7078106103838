import React, { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";

type Props = {
  id: string;
} & UseFormRegisterReturn<string>;

const DateInput = forwardRef<HTMLInputElement, Props>((props, ref) => (
  <div className="relative mt-1 w-xs max-w-xs">
    <div
      className="inline-block cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
      <input
        type="date"
        className="w-full border-none h-[36px] py-2 px-3 text-sm leading-5 text-gray-900 focus:ring-0"
        {...props}
        ref={ref}
      />
    </div>
  </div>
));

export default DateInput;
