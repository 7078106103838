import { AxiosInstance } from "axios";
import { Deposit, SalesChannel } from "./types";

export type GetQueryParams = {
  amount: number;
  minDaysDuration: number;
  maxDaysDuration: number;
  expectedSalesChannels?: SalesChannel[];
  banksToInclude?: string[],
  excludeCurrentAccountRequired?: boolean;
  excludeOnlyForNewCustomers?: boolean;
  excludeOnlyForNewFunds?: boolean;
}

const deposits = (axios: AxiosInstance) => ({
  get: async (params: GetQueryParams): Promise<Deposit[]> => {
    const response = await axios.get<Deposit[]>("/deposits", { params });
    return response.data;
  },
  getByUid: async (uid: string): Promise<Deposit | null> => {
    const response = await axios.get<Deposit[]>(`/deposits`, { params: { uid } });
    return response.data[0] ?? null;
  }
})

export default deposits;
