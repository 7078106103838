import React from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../login/LoginButton";
import LogoutButton from "../login/LogoutButton";

const UserNavMobile = () => {

  const { isLoading, isAuthenticated, user } = useAuth0();

  return (
    <div className="border-t border-gray-700 pt-4 pb-3">
      {isAuthenticated && <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          <UserCircleIcon className="h-8 w-8 rounded-full" />
        </div>
        <div className="ml-3">
          <div className="text-base font-medium leading-none text-dark">{user?.name}</div>
          <div className="text-sm font-medium leading-none text-gray-400">{user?.email}</div>
        </div>
      </div>
      }
      <div className="mt-3 space-y-1 px-2">
        {!isLoading && !isAuthenticated && (
          <LoginButton display="touch" className="rounded-md px-3 py-2 font-medium text-gray-400 hover:bg-gray-700 hover:text-white w-full">
            Zaloguj się
          </LoginButton>
        )}
        {!isLoading && isAuthenticated && (
          <LogoutButton className="rounded-md px-3 py-2 font-medium text-gray-400 hover:bg-gray-700 hover:text-white w-full" />
        )}
      </div>
    </div>
  );
};

export default UserNavMobile;
