import React from "react";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

type Props = {
  positive: boolean;
  positiveText: string;
  negativeText: string;
};

const ChecklistItem = ({ positive, positiveText, negativeText }: Props) => (
  <div className="flex flex-1 items-center p-1 bg-gray-50 rounded-xl border border-gray-100 sm:flex-col sm:p-2">
    <div className="p-1">
      {positive ? (
        <CheckCircleIcon className="h-8 text-green-600" />
      ) : (
        <ExclamationCircleIcon className="h-8 text-yellow-600" />
      )}
    </div>
    <div>
      {positive ? positiveText : negativeText}
    </div>
  </div>
);

export default ChecklistItem;
