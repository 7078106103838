import React, { useState } from "react";
import AdditionalFiltersModal from "./modal/AdditionalFiltersModal";
import { Bank } from "../../../api/types";
import { Filters, UpdateFiltersFn } from "../types";
import SelectedChannels from "./SelectedChannels";
import AdditionalFiltersButton from "./AdditionalFiltersButton";
import SelectedExclusions from "./SelectedExclusions";

type Props = {
  banks: Bank[];
  filters: Filters;
  updateFilters: UpdateFiltersFn;
};

const AdditionalFilters = ({ banks, filters, updateFilters }: Props) => {
  const [additionalFiltersModalOpen, setAdditionalFiltersModalOpen] = useState(false);
  const [defaultOpenSections, setDefaultOpenSections] = useState({
    banks: true,
    exclusions: true,
    availability: true
  });

  const closeModal = () => {
    setDefaultOpenSections({ banks: true, exclusions: true, availability: true });
    setAdditionalFiltersModalOpen(false);
  }

  return (
    <div className="flex shrink-0 w-full items-start space-x-2 py-2 text-sm">
      <AdditionalFiltersButton
        onClick={() => setAdditionalFiltersModalOpen(true)}
        filters={filters} />

      <div className="hidden sm:flex sm:overflow-x-auto sm:space-x-2">
        <SelectedExclusions
          filters={filters}
          updateFilters={updateFilters}
          onClick={() => {
            setDefaultOpenSections({ banks: false, exclusions: true, availability: false });
            setAdditionalFiltersModalOpen(true)
          }} />
        <SelectedChannels
          filters={filters}
          updateFilters={updateFilters}
          onClick={() => {
            setDefaultOpenSections({ banks: false, exclusions: false, availability: true });
            setAdditionalFiltersModalOpen(true)
          }} />
      </div>

      <AdditionalFiltersModal
        isOpen={additionalFiltersModalOpen}
        onClose={closeModal}
        filters={filters}
        updateFilters={updateFilters}
        banks={banks}
        defaultOpenSections={defaultOpenSections}
      />
    </div>
  );
};

export default AdditionalFilters;
