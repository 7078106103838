import React from "react";
import ItemValue from "./ItemValue";
import { UserDeposit } from "../../../api/types";
import { computeInterest, computeNetInterest } from "../../interestCompounding";
import { isBefore, parseISO } from "date-fns";
import { formatAmount, getToday } from "../../utils";
import NetInterestTooltip from "../../interest/NetInterestTooltip";

type Props = {
  userDeposit: UserDeposit;
  finished: boolean;
};

const InterestCompounding = ({ userDeposit, finished }: Props) => {
  const today = getToday();
  const startDate = parseISO(userDeposit.start);
  const endDate = parseISO(userDeposit.end);
  const currentInterest = isBefore(today, startDate) ? 0 : computeNetInterest(computeInterest(userDeposit.amount, startDate, today, userDeposit.annualPercentageRate));
  const finalInterest = computeNetInterest(computeInterest(userDeposit.amount, startDate, endDate, userDeposit.annualPercentageRate));

  return (
    <ItemValue
      label={(<>
        Zysk
        <NetInterestTooltip />
      </>)}
      value={(
        <>
          {!finished && (
            <>
              <span>{formatAmount(currentInterest)}</span>
              <span> / </span>
            </>
          )}
          <span>{formatAmount(finalInterest)}</span>
        </>
      )} />
  );
};

export default InterestCompounding;
