import React from "react";
import { UserDeposit } from "../../../api/types";
import DepositActions from "./DepositActions";
import { formatDateFromString } from "../../utils";
import CurrentAccountItem from "../../deposit/details/CurrentAccountItem";
import DetailsItem from "../../deposit/details/DetailsItem";
import AmountItem from "../../deposit/details/AmountItem";
import LimitsItem from "../../deposit/details/LimitsItem";
import AdditionalInfoList from "../../deposit/details/AdditionalInfoList";
import AttachmentsSection from "../../deposit/details/AttachmentsSection";

type Props = {
  userDeposit: UserDeposit;
  onEdit: (userDeposit: UserDeposit) => void;
  onClose: (userDeposit: UserDeposit) => void;
  onDelete: (userDeposit: UserDeposit) => void;
};

const UserDepositDetails = ({ userDeposit, onEdit, onClose, onDelete }: Props) => (
  <div className="bg-white p-2">

    <div className="flex flex-1 flex-col md:flex-row md:space-x-2">
      <div className="flex flex-1 flex-col md:flex-row">
        <DetailsItem
          label="Data założenia"
          content={(<>{formatDateFromString(userDeposit.start)}</>)}
        />
        <DetailsItem
          label="Data zakończenia"
          content={(<>{formatDateFromString(userDeposit.end)}</>)}
        />
      </div>
    </div>

    {userDeposit.deposit ? (
      <>
        <div className="flex flex-1 flex-col md:flex-row md:space-x-2">
          <div className="flex flex-1 flex-col">
            <CurrentAccountItem deposit={userDeposit.deposit} />
            <DetailsItem
              label={"Klient"}
              content={userDeposit.deposit.onlyForNewCustomers ? (
                <>Tylko dla nowych klientów</>
              ) : (
                <>Każdy</>
              )}
            />
            <DetailsItem
              label={"Środki"}
              content={userDeposit.deposit.onlyForNewFunds ? (
                <div>
                  <div>
                    Tylko dla nowych środków.
                  </div>
                  <div>
                    {userDeposit.deposit.newFundsAdditionalInfo}
                  </div>
                </div>
              ) : (
                <>Dowolne środki</>
              )}
            />
          </div>

          <div className="flex flex-1 flex-col">
            <AmountItem deposit={userDeposit.deposit} />
            <LimitsItem deposit={userDeposit.deposit} />
            <DetailsItem
              label={"Gwarancje bankowe"}
              content={(<>{userDeposit.deposit.bankGuarantees}</>)}
            />
          </div>
        </div>

        <div className="flex flex-1 flex-col py-2">
          <AdditionalInfoList deposit={userDeposit.deposit} />
          <AttachmentsSection attachments={userDeposit.deposit.attachments} />
        </div>
      </>
    ) : (
      <div className="py-2 text-gray-500">Ta lokata nie ma zapisanej oferty banku</div>
    )}

    <DepositActions
      userDeposit={userDeposit}
      onEdit={onEdit}
      onClose={onClose}
      onDelete={onDelete} />
  </div>
);

export default UserDepositDetails;
