import React from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

type Props = {
  expanded: boolean;
  onClick: () => void;
};

const ShowMore = ({ expanded, onClick }: Props) => (
  <button
    type="button"
    onClick={onClick}
    className="text-secondary hover:text-primary border border-transparent hover:border hover:border-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-sm text-sm p-2 w-10 h-10 text-center"
    aria-label="Pokaż więcej">
    <ChevronRightIcon
      className={`${expanded ? "rotate-90 transform" : ""} h-6 w-6 -ml-0.5`} />
  </button>
);

export default ShowMore;
