import React, { PropsWithChildren } from "react";
import { classNames } from "../../../utils";

type Props = {
  className?: string;
  selected: boolean;
  onClick: () => void;
  "aria-label"?: string;
};

const SelectableItem = ({ className, onClick, selected, children, "aria-label": ariaLabel }: PropsWithChildren<Props>) => (
  <button
    type="button"
    className={classNames(className,
      `h-full w-full p-1 flex justify-center items-center border rounded-sm hover:shadow-border-inner hover:shadow-primary/50 hover:border-primary ${selected ? "shadow-border-inner shadow-primary/100" : "border-1"}`
    )}
    onClick={onClick}
    aria-label={ariaLabel}>
    {children}
  </button>
);

export default SelectableItem;
