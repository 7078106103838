import React from "react";
import Filters from "../components/filter/Filters";
import DepositsList from "../components/deposit/DepositsList";
import useFilters from "../components/filter/useFilters";
import useBanks from "../components/deposit/useBanks";

const Deposits = () => {
  const { filters, updateFilters } = useFilters();
  const banks = useBanks();

  return (
    <>
      <Filters
        banks={banks}
        filters={filters}
        updateFilters={updateFilters}
      />

      <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="px-4 py-2 sm:px-0">
            <DepositsList banks={banks} filters={filters} />
          </div>
        </div>
      </main>
    </>
  );
};

export default Deposits;
