import React, { PropsWithChildren, useContext } from "react";
import { useCookie } from "react-use";
import { Consent } from "./modal/types";
import CookiesModal from "./modal/CookiesModal";

const CookiesConsentContext = React.createContext<{ consent: Consent }>({ consent: { analytics: false } })

export const useCookiesConsentContext = () => useContext(CookiesConsentContext);

const CookiesConsentChecker = ({ children }: PropsWithChildren) => {
  const [consentCookieValue, setConsentCookieValue] = useCookie("cookie-consent");
  const showCookiesModal = consentCookieValue === null;
  const consent: Consent = consentCookieValue ? JSON.parse(consentCookieValue) : { analytics: false };

  const accept = (consent: Consent) => {
    setConsentCookieValue(JSON.stringify(consent), { expires: 365 });
  }

  return (
    <CookiesConsentContext.Provider value={{ consent }}>
      {children}

      {showCookiesModal && (
        <CookiesModal
          accept={(consent) => {
            accept(consent)
          }}
          acceptAll={() => {
            accept({ analytics: true });
          }}
        />
      )}
    </CookiesConsentContext.Provider>
  );
};

export default CookiesConsentChecker;
