import React from "react";
import DetailsItem from "./DetailsItem";
import { Deposit } from "../../../api/types";
import { formatAmount } from "../../utils";

type Props = {
  deposit: Deposit;
};

const Content = ({ deposit }: { deposit: Deposit }) => {
  if (deposit.maxAmount) {
    return (<span>{formatAmount(deposit.minAmount)} &ndash; {formatAmount(deposit.maxAmount)}</span>)
  }

  return (<span>od {formatAmount(deposit.minAmount)}</span>)
}


const AmountItem = ({ deposit }: Props) => (
  <DetailsItem
    label={"Kwota lokaty"}
    content={(<Content deposit={deposit} />)}
  />
);

export default AmountItem;
