import React from "react";
import FormItem from "./FormItem";
import { useFormContext } from "react-hook-form";
import DateInput from "../inputs/DateInput";
import { isBefore, parseISO } from "date-fns";

const EndDateField = () => {
  const { register } = useFormContext();

  return (
    <FormItem
      htmlFor="user-deposit-end-date"
      label="Data zakończenia"
      name="endDate"
      content={(
        <DateInput
          id="user-deposit-end-date"
          {...register("end", {
            required: "Data zakończenia jest wymagana",
            validate: (value, formValues) => {
              if (value && formValues.startDate && !isBefore(parseISO(formValues.startDate), parseISO(value))) {
                return "Data zakończenia musi być późniejsza niż data rozpoczęcia"
              }
            }
          })} />
      )}
    />
  );
};

export default EndDateField;
