import React from "react";
import { Deposit } from "../../../api/types";
import AdditionalInfoList from "./AdditionalInfoList";

type Props = {
  deposit: Deposit;
};

const AdditionalInfoSection = ({ deposit }: Props) => (
  <AdditionalInfoList deposit={deposit} />
);

export default AdditionalInfoSection;
