/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link as RouterLink } from "react-router-dom"
import { paths } from "../../routes/links";

const Footer = () => (
  <footer className="bg-white">
    <div className="container px-6 py-8 mx-auto">
      <div className="text-center">
        <a href="#" className="text-3xl font-bold text-dark hover:text-primary">
          fintee.pl
        </a>
        <div>
          <small>Twój asystent lokat</small>
        </div>

        <div className="flex flex-wrap justify-center mt-5 -mx-4">
          <RouterLink
            to={paths.deposits}
            className="mx-4 text-dark transition-colors duration-300 hover:text-primary">
            Ranking lokat
          </RouterLink>
          <RouterLink
            to={paths.privacyPolicy}
            className="mx-4 text-dark transition-colors duration-300 hover:text-primary">
            Polityka prywatności
          </RouterLink>
          <RouterLink
            to={paths.cookiesInfo}
            className="mx-4 text-dark transition-colors duration-300 hover:text-primary">
            Ciasteczka
          </RouterLink>
        </div>
      </div>

      <hr className="my-6 border-gray-200 md:my-10" />

      <div className="flex flex-col items-center sm:flex-row sm:justify-between">
        <p className="text-gray-500">© Copyright 2023</p>
      </div>
    </div>
  </footer>
);

export default Footer;
