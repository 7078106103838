import React from "react";
import Section from "../Section";
import { Exclusions } from "../../types";
import ExclusionItem from "./ExclusionItem";

export

type Props = {
  defaultOpen: boolean;
  exclusions: Exclusions;
  onChange: (exclusions: Exclusions) => void;
};

const ExclusionsSection = ({ defaultOpen, exclusions, onChange }: Props) => {
  return (
    <Section header="Wymagania" defaultOpen={defaultOpen}>
      <div className="flex flex-wrap">
        <ExclusionItem
          selected={exclusions.currentAccountRequired}
          onClick={() => onChange({ ...exclusions, currentAccountRequired: !exclusions.currentAccountRequired })}
          label="Bez wymaganego konta osobistego" />
        <ExclusionItem
          selected={exclusions.onlyForNewCustomers}
          onClick={() => onChange({ ...exclusions, onlyForNewCustomers: !exclusions.onlyForNewCustomers })}
          label="Bez promocji dla nowych klientów" />
        <ExclusionItem
          selected={exclusions.onlyForNewFunds}
          onClick={() => onChange({ ...exclusions, onlyForNewFunds: !exclusions.onlyForNewFunds })}
          label="Bez promocji na nowe środki" />
      </div>
    </Section>
  );
};

export default ExclusionsSection;
