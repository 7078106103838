import { useAnalyticsContext } from "./AnalyticsInitializer";
import ReactGA from "react-ga4";
import { useMemo } from "react";
import { Bank, Deposit } from "../../api/types";

const useAnalyticsTracker = () => {
  const { enabled } = useAnalyticsContext();

  return useMemo(() => {
    return {
      pageView: (page: string) => {
        if (enabled) {
          ReactGA.event("page_view", { page_location: page });
        }
      },
      depositSelected: (deposit: Deposit, bank: Bank | null) => {
        if (enabled) {
          ReactGA.event("select_item", {
            item_list_name: "deposits",
            items: [{
              item_id: deposit.uid,
              item_name: `${deposit.productName}`,
              item_brand: `${bank?.name}`,
            }]
          });
        }
      },
      depositDetailsShown: (deposit: Deposit, bank: Bank | null) => {
        if (enabled) {
          ReactGA.event("view_item_list", {
            item_list_name: "deposits",
            items: [{
              item_id: deposit.uid,
              item_name: `${deposit.productName}`,
              item_brand: `${bank?.name}`,
            }]
          });
        }
      },
      loginClicked: (location: string) => {
        if (enabled) {
          ReactGA.event("login_clicked", { location });
        }
      },
      savingDepositRequested: (isAuthenticated: boolean) => {
        if (enabled) {
          ReactGA.event("saving_deposit_requested", { isAuthenticated });
        }
      },
      savedDepositsDisplayed: () => {
        if (enabled) {
          ReactGA.event("saved_deposits_displayed");
        }
      },
      userDepositAdded: () => {
        if (enabled) {
          ReactGA.event("user_deposit_added");
        }
      },
      userDepositUpdated: () => {
        if (enabled) {
          ReactGA.event("user_deposit_updated");
        }
      },
      userDepositClosed: () => {
        if (enabled) {
          ReactGA.event("user_deposit_closed");
        }
      },
      closedDepositsToggled: () => {
        if (enabled) {
          ReactGA.event("closed_deposits_toggled");
        }
      }
    }
  }, [enabled]);
}

export default useAnalyticsTracker;
