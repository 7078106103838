import React from "react";
import { Combobox } from "@headlessui/react";
import { formatAmount } from "../../utils";

type Props = {
	value: number
};

const AmountSelectOption = ({ value }: Props) => (
  <Combobox.Option
    className={({ active }) =>
      `relative cursor-default select-none py-2 pl-10 pr-4 ${
        active ? "bg-primary text-white" : "text-gray-900"
      }`
    }
    value={value}
  >
    {formatAmount(value)}
  </Combobox.Option>
);

export default AmountSelectOption;
