import React from "react";
import DetailsItem from "./DetailsItem";
import { Deposit } from "../../../api/types";

type Props = {
  deposit: Deposit;
};

const Content = ({ deposit }: { deposit: Deposit }) => {
  if (deposit.currentAccountRequired) {
    if (deposit.currentAccountProductName) {
      return (<>Wymagane: {deposit.currentAccountProductName}</>);
    } else {
      return (<>Wymagane</>);
    }
  } else {
    return (<>Niewymagane</>);
  }
}

const CurrentAccountItem = ({ deposit }: Props) => (
  <DetailsItem
    label={"Konto osobiste"}
    content={<Content deposit={deposit} />}
  />
);

export default CurrentAccountItem;
