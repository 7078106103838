import React, { Fragment } from "react";
import { UserDeposit } from "../../../api/types";
import ProgressBar from "./ProgressBar";
import { differenceInDays, isBefore, parseISO } from "date-fns";
import DepositsListItemSummary from "./DepositsListItemSummary";
import { Disclosure, Transition } from "@headlessui/react";
import UserDepositDetails from "./UserDepositDetails";
import { getToday } from "../../utils";

type Props = {
  userDeposit: UserDeposit;
  onUserDepositEdit: (userDeposit: UserDeposit) => void;
  onUserDepositClose: (userDeposit: UserDeposit) => void;
  onUserDepositDelete: (userDeposit: UserDeposit) => void;
}

function getCompletionPercentage(daysLeft: number, startDate: Date, endDate: Date, today: Date) {
  if (isBefore(today, startDate)) {
    return 0;
  }
  if (daysLeft <= 0) {
    return 100;
  }

  const numberOfDays = differenceInDays(endDate, startDate);
  return Math.floor((numberOfDays - daysLeft) / numberOfDays * 100);
}

const DepositsListItem = ({ userDeposit, onUserDepositEdit, onUserDepositClose, onUserDepositDelete }: Props) => {
  const today = getToday();
  const startDate = parseISO(userDeposit.start);
  const endDate = parseISO(userDeposit.end);
  const daysLeft = differenceInDays(endDate, today);
  const percentage = getCompletionPercentage(daysLeft, startDate, endDate, today);
  const bank = userDeposit.bank ?? null;

  return (
    <div className="border-2 border-gray-100 rounded-lg lg:border-0 lg:rounded-none lg:first:rounded-t-lg lg:last:rounded-b-lg overflow-hidden">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full text-left">
              <ProgressBar open={open} percentage={percentage}>
                <DepositsListItemSummary userDeposit={userDeposit} bank={bank} daysLeft={daysLeft} />
              </ProgressBar>
            </Disclosure.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Disclosure.Panel>
                <UserDepositDetails
                  userDeposit={userDeposit}
                  onEdit={onUserDepositEdit}
                  onClose={onUserDepositClose}
                  onDelete={onUserDepositDelete} />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default DepositsListItem;
