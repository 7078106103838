import React from "react";
import { DurationDisplayType } from "../../api/types";

const yearsFormatter = new Intl.NumberFormat("pl-PL", { style: "unit", unit: "year", unitDisplay: "long" });
const monthsFormatter = new Intl.NumberFormat("pl-PL", { style: "unit", unit: "month", unitDisplay: "short" });

type Props = {
  durationDisplayType: DurationDisplayType;
  durationInDays: number;
};

const computeDuration = (durationInDays: number, durationDisplayType: DurationDisplayType) => {
  if (durationDisplayType === "YEAR") {
    const inYears = durationInDays / 360;
    return yearsFormatter.format(inYears)
  }

  if (durationDisplayType === "MONTH") {
    return monthsFormatter.format(durationInDays / 30);
  }

  return `${durationInDays} dni`;
};

const Duration = ({ durationInDays, durationDisplayType }: Props) => {
  return <span>{computeDuration(durationInDays, durationDisplayType)}</span>;
};

export default Duration;
