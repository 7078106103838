import { Duration } from "../components/filter/types";

export const paths = {
  root: "/",
  deposits: "/",
  savedDeposits: "/twoje-lokaty",
  privacyPolicy: "/polityka-prywatnosci",
  cookiesInfo: "/cookies-info",
  newsletterSubscribed: "/newsletter",
  newsletterConfirmation: "/newsletter-potwierdzenie",
}

export const links = {
  deposits: (amount: number, duration: Duration) => {
    return `${paths.deposits}?amount=${amount}&dMin=${duration.min}&dMax=${duration.max}`
  }
}
