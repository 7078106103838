import React from "react";
import Section from "../Section";
import { Bank } from "../../../../api/types";
import BankItem from "./BankItem";

type Props = {
  defaultOpen?: boolean;
  banks: Bank[];
  selectedBanksIds: string[];
  onChange: (banksIds: string[]) => void;
};

const BanksSection = ({ defaultOpen, selectedBanksIds, onChange, banks }: Props) => {
  const toggleBank = (bank: Bank) => {
    if (selectedBanksIds.includes(bank.uid)) {
      onChange(selectedBanksIds.filter(id => id !== bank.uid));
    } else {
      onChange([...selectedBanksIds, bank.uid]);
    }
  }

  return (
    <Section header="Banki" defaultOpen={defaultOpen}>
      <div className="flex flex-wrap">
        {banks.map(bank => {
          const selected = selectedBanksIds.find(id => id === bank.uid) !== undefined;
          return (
            <BankItem
              key={bank.uid}
              selected={selected}
              bank={bank}
              onSelect={toggleBank} />
          );
        })}
      </div>
    </Section>
  );
};

export default BanksSection;
