import React from "react";
import DurationFilter from "./duration/DurationFilter";
import AmountFilter from "./amount/AmountFilter";
import { Filters as FiltersType, UpdateFiltersFn } from "./types";
import AdditionalFilters from "./additional/AdditionalFilters";
import { Bank } from "../../api/types";
import BanksFilter from "./banks/BanksFilter";

type Props = {
  banks: Bank[];
  filters: FiltersType;
  updateFilters: UpdateFiltersFn;
};

const Filters = ({ banks, filters, updateFilters }: Props) => (
  <section className="mx-auto max-w-7xl pt-3 px-4 sm:px-6 lg:px-8">
    <form
      onSubmit={(e) => {
        e.preventDefault()
      }}>
      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
        <AmountFilter value={filters.amount} onChange={(amount) => updateFilters({ amount })} />

        <DurationFilter value={filters.duration} onChange={(duration) => updateFilters({ duration })} />
      </div>
      <BanksFilter banks={banks} selectedBanksIds={filters.banksIds} updateFilters={updateFilters} />
      <AdditionalFilters banks={banks} filters={filters} updateFilters={updateFilters} />
    </form>
  </section>
);

export default Filters;
