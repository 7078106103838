import React, { ReactNode } from "react";

type Props = {
  icon: ReactNode
  content: ReactNode
  condition?: boolean;
};

const InfoItem = ({ icon, content, condition }: Props) => {
  if (condition === false) {
    return null;
  }

  return (
    <div className="flex items-center space-x-2">
      <div>{icon}</div>
      <div>{content}</div>
    </div>
  );
};

export default InfoItem;
