import React from "react";
import QuickFilterLink from "../QuickFilterLink";

const DepositLinksSection = () => (
  <section className="bg-white border-b">
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="mx-auto max-w-screen-md sm:text-center">
        <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
          Już teraz sprawdź dostępne lokaty
        </h2>
        <p className="text-gray-500 sm:text-xl">
          Skorzystaj z najlepszej porównywarki lokat, aby zyskać jak najwięcej ze swoich oszczędności. Jakie lokaty Cię interesują?
        </p>
        <div className="mx-auto max-w-7xl pt-6 px-4 sm:px-6 lg:px-8">
          <dl className="py-1">
            <QuickFilterLink
              amount={10_000}
              duration={{ min: 30, max: 90 }}>
              10 000 zł na okres 1-3 miesięcy
            </QuickFilterLink>
            <QuickFilterLink
              amount={20_000}
              duration={{ min: 30, max: 270 }}>
              20 000 zł na okres 1-9 miesięcy
            </QuickFilterLink>
            <QuickFilterLink
              amount={50_000}
              duration={{ min: 30, max: 270 }}>
              50 000 zł na okres 1-9 miesięcy
            </QuickFilterLink>
            <QuickFilterLink
              amount={10_000}
              duration={{ min: 1, max: 1800 }}>
              Pokaż wszystkie
            </QuickFilterLink>
          </dl>
        </div>
      </div>
    </div>
  </section>
);

export default DepositLinksSection;
