import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import InfoItem from "./InfoItem";
import { Deposit } from "../../../api/types";
import { formatDateFromString } from "../../utils";
import InterestCompoundingText from "../InterestCompoundingText";

type Props = {
  deposit: Deposit;
};

const AdditionalInfoList = ({ deposit }: Props) => (
  <div>
    <InfoItem
      icon={(<InformationCircleIcon className="h-4" />)}
      content={<>Naliczanie odsetek <InterestCompoundingText value={deposit.interestCompounding} /></>}
    />
    <InfoItem
      icon={(<InformationCircleIcon className="h-4" />)}
      content={(
        <span>
          Oferta dostępna od {formatDateFromString(deposit.offerAvailableFrom)}
          {deposit.offerAvailableTo && (` do ${formatDateFromString(deposit.offerAvailableTo)}`)}
        </span>
      )}
    />

    {deposit.additionalInfos.map((info, idx) => (
      <InfoItem
        key={idx}
        icon={(<InformationCircleIcon className="h-4" />)}
        content={info.value}
      />
    ))}
  </div>
);

export default AdditionalInfoList;
