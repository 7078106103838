import React, { PropsWithChildren } from "react";
import { Api } from "./types";
import Context from "./context";

type Props = {
  api: Api;
};

const ApiProvider = ({ api, children }: PropsWithChildren<Props>) => (
  <Context.Provider value={api}>{children}</Context.Provider>
);

export default ApiProvider;
