import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import CookieSection from "./CookieSection";
import { Consent } from "./types";

type Props = {
  accept: (consent: Consent) => void;
  acceptAll: () => void;
  back: () => void;
};

const CookiesConfiguration = ({ accept, acceptAll, back }: Props) => {
  const [analytics, setAnalytics] = useState(false);

  return (
    <>
      <Dialog.Title
        as="h3"
        className="text-lg font-medium leading-6 text-gray-900 p-4"
      >
        Dbamy o Twoją prywatność
      </Dialog.Title>

      <div className="p-4">

        <CookieSection
          name="Pliki cookies wydajności i personalizacji"
          selected={analytics}
          onChange={(value) => {
            setAnalytics(value);
          }}>
          Dzięki tym plikom jesteśmy w stanie analizować ruch na stronie, a w konsekwencji poprawiać wydajność portalu oraz ulepszać działanie
          komponentów. Możemy ustalić, które elementy portalu są najczęściej odwiedzane, a które mniej, oraz w jaki sposób użytkownicy korzystają z
          funkcjonalności jakie oferujemy.
        </CookieSection>

      </div>

      <div className="flex justify-between p-4">
        <button
          type="button"
          className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          onClick={back}>
          Wróć
        </button>
        <button
          type="button"
          className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          onClick={() => {
            accept({ analytics });
          }}>
          Zapisz wybrane
        </button>
        <button
          type="button"
          className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
          onClick={acceptAll}>
          Akceptuj wszystkie
        </button>
      </div>
    </>
  );
};

export default CookiesConfiguration;
