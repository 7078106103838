import React from "react";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import Routes from "./routes";
import { Api } from "./api/types";
import ApiProvider from "./api/ApiProvider";
import AnalyticsInitializer from "./components/analytics/AnalyticsInitializer";
import LocationTracker from "./components/analytics/LocationTracker";
import CookiesConsentChecker from "./components/cookies/CookiesConsentChecker";
import ErrorBoundary from "./components/errorHandler/ErrorBoundary";
import Auth from "./components/auth/Auth";

type Props = {
  api: Api;
}

const App = ({ api }: Props) => {
  return (
    <ErrorBoundary>
      <CookiesProvider>
        <CookiesConsentChecker>
          <AnalyticsInitializer>
            <ApiProvider api={api}>
              <BrowserRouter>
                <Auth>
                  <Routes />

                  <LocationTracker />
                </Auth>
              </BrowserRouter>
            </ApiProvider>
          </AnalyticsInitializer>
        </CookiesConsentChecker>
      </CookiesProvider>
    </ErrorBoundary>
  );
};

export default App;
