import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CookiesInformation from "./CookiesInformation";
import CookiesConfiguration from "./CookiesConfiguration";
import { Consent } from "./types";

const noop = () => {
};

type Props = {
  accept: (consent: Consent) => void;
  acceptAll: () => void;
};

const CookiesModal = ({ accept, acceptAll }: Props) => {
  const [showConfiguration, setShowConfiguration] = useState(false);

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={noop}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >

              <Dialog.Panel
                className="w-full max-w-3xl transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                {showConfiguration ? (
                  <CookiesConfiguration
                    accept={accept}
                    acceptAll={acceptAll}
                    back={() => setShowConfiguration(false)} />
                ) : (
                  <CookiesInformation
                    acceptAll={acceptAll}
                    showConfiguration={() => setShowConfiguration(true)} />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CookiesModal;
