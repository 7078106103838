import React, { ComponentType } from "react";
import SelectableItem from "./SelectableItem";

type Props = {
  selected: boolean;
  onClick: () => void;
  label: string;
  icon: ComponentType<{ className: string }>;
};

const AvailabilityItem = ({ selected, onClick, label, icon: Icon }: Props) => (
  <div className="w-1/2 max-w-1/2 h-14 sm:h-12 sm:w-[12.5%] sm:max-w-[12.5%] p-1">
    <SelectableItem selected={selected} onClick={onClick}>
      <div className="flex space-x-2 justify-center items-center p-3">
        <Icon className="h-5" />
        <span className="text-xs">{label}</span>
      </div>
    </SelectableItem>
  </div>
);

export default AvailabilityItem;
