import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

type Props = {
  htmlFor: string;
  name: string;
  label: string;
  content: React.ReactNode;
};

const FormItem = ({ htmlFor, name, label, content }: Props) => {
  const { formState: { errors } } = useFormContext();

  return (
    <div className="flex flex-1 flex-col">
      <label className="text-gray-700" htmlFor={htmlFor}>
        {label}
      </label>
      <div className="flex flex-1 items-center">
        {content}
      </div>
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => message && (
          <div className="py-2">
            <p className="flex items-center text-red-700">
              <ExclamationCircleIcon className="h-4 mr-1" />
              {message}
            </p>
          </div>
        )} />
    </div>
  );
};

export default FormItem;
