import React from "react";
import { BanknotesIcon, CheckCircleIcon, ClockIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Deposit } from "../../api/types";
import Duration from "./Duration";
import PropertyWithIcon from "./base/PropertyWithIcon";
import { computeDepositInterest, computeNetInterest } from "../interestCompounding";
import { formatAmount } from "../utils";
import NetInterestTooltip from "../interest/NetInterestTooltip";

type Props = {
  selectedAmount: number;
  deposit: Deposit;
};

const ShortDetails = ({ selectedAmount, deposit }: Props) => (
  <div className="w-full flex flex-col sm:flex-row sm:align-top sm:items-stretch sm:flex-col">
    <PropertyWithIcon
      icon={(<ClockIcon className="h-6" />)}>
      <Duration durationInDays={deposit.durationInDays} durationDisplayType={deposit.durationDisplayType} />
    </PropertyWithIcon>
    <PropertyWithIcon
      icon={(<BanknotesIcon className="h-6" />)}>
      <span>{formatAmount(computeNetInterest(computeDepositInterest(selectedAmount, deposit)))}</span>
      <small>&nbsp;zysku</small>
      <NetInterestTooltip />
    </PropertyWithIcon>
    {deposit.currentAccountRequired ? (
      <PropertyWithIcon
        icon={(<ExclamationCircleIcon className="h-6" />)}>
        <span className="whitespace-pre-line">
          Konto
          wymagane
        </span>
      </PropertyWithIcon>
    ) : (
      <PropertyWithIcon
        icon={(<CheckCircleIcon className="h-6" />)}>
        <span className="whitespace-pre-line">
          Nie&nbsp;wymaga konta
        </span>
      </PropertyWithIcon>
    )}
  </div>
);

export default ShortDetails;
