import React, { Fragment, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FormProvider, useForm } from "react-hook-form";
import { Values } from "./FormFields";

type Props = {
  title: string;
  defaultValues: Values | (() => Promise<Values>);
  onSubmit: (values: Values) => Promise<any>;
  onClose: () => void;
};

const UserDepositModalContainer = ({ title, defaultValues, onSubmit, onClose, children }: PropsWithChildren<Props>) => {
  const formMethods = useForm<Values>({ defaultValues })

  return (
    <FormProvider {...formMethods}>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto min-w-full">
              <div className="flex min-h-full items-center justify-center text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className="w-full max-w-6xl transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 p-4"
                    >
                      {title}
                    </Dialog.Title>

                    {children}

                    <div className="flex justify-between p-4">
                      <button
                        type="button"
                        className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        onClick={onClose}>
                        Zamknij
                      </button>
                      <button
                        type="submit"
                        className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer">
                        Zapisz
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </form>
        </Dialog>
      </Transition>
    </FormProvider>
  );
};

export default UserDepositModalContainer;
