import React from "react";

type Props = {
  icon: React.FunctionComponent<any>;
  header: string;
  text: string;
};

const RecommendationItem = ({ icon: Icon, header, text }: Props) => (
  <div className="flex flex-1 flex-col sm:flex-row sm:items-center sm:space-x-2">
    <div className="hidden sm:block">
      <Icon className="h-6 text-secondary" />
    </div>
    <div>
      <h4>{header}</h4>
      <small className="text-secondary">{text}</small>
    </div>
  </div>
);

export default RecommendationItem;
