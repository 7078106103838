import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import useAnalyticsTracker from "./useAnalyticsTracker";

const LocationTracker = () => {
  const tracker = useAnalyticsTracker();
  const location = useLocation();

  useEffect(() => {
    tracker.pageView(location.pathname + location.search);
  }, [location, tracker]);

  return null;
};

export default LocationTracker;
