import React from "react";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/outline";
import LoginButton from "../../login/LoginButton";

const MainSection = () => (
  <section>
    <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl">
        Twoje lokaty w jednym miejscu
      </h1>
      <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48">
        Utwórz konto i uzyskaj dostęp do nowych funkcjonalności
      </p>
      <div className="flex flex-col mb-4 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
        <LoginButton
          className="bg-white inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100"
          screenHint={"signup"}>
          Zarejestruj się
        </LoginButton>
      </div>
      <p className="mb-10 text-md font-normal text-gray-500">
        Masz już konto? <LoginButton className="inline text-md text-primary hover:text-secondary">Zaloguj się</LoginButton>
      </p>
      <div>
        <a
          href="#features"
          className="flex justify-center items-center pt-5 px-5 text-base font-medium text-center text-gray-900 hover:underline hover:text-primary focus:ring-4 focus:ring-gray-100">
          <ChevronDoubleDownIcon className="h-4 w-4 mx-2" />
          Zobacz co zyskasz z kontem fintee
          <ChevronDoubleDownIcon className="h-4 w-4 mx-2" />
        </a>
      </div>
    </div>
  </section>
);

export default MainSection;
