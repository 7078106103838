import { useApi } from "../../api/context";
import { useReducer } from "react";
import { UserDeposit } from "../../api/types";
import { useDeepCompareEffect } from "react-use";

type Action = {
  type: "depositsReceived"
  payload: { result: UserDeposit[] }
} | {
  type: "loading"
} | {
  type: "loadingError",
  payload: { error: any }
}

export type LoadingStatus = "idle" | "loading" | "success" | "error";

type State = {
  status: LoadingStatus;
  result: UserDeposit[];
  error: any | null
}

function depositsReducer(state: State, action: Action): State {
  switch (action.type) {
  case "loading": {
    return {
      ...state,
      status: "loading",
      error: null
    }
  }
  case "depositsReceived": {
    return {
      ...state,
      status: "success",
      result: action.payload.result,
      error: null
    };
  }
  case "loadingError": {
    return {
      ...state,
      status: "error",
      result: [],
      error: action.payload.error
    };
  }
  default:
    return state;
  }
}

const getActiveDeposits = (deposits: UserDeposit[]) => deposits.filter(d => d.status === "ACTIVE");

const getClosedDeposits = (deposits: UserDeposit[]) => deposits.filter(d => d.status === "CLOSED");

const useUserDeposits = () => {
  const api = useApi();
  const [state, dispatch] = useReducer(depositsReducer, {
    status: "idle",
    result: [],
    error: null
  });

  useDeepCompareEffect(() => {
    dispatch({ type: "loading" })
    api.userDeposits.getDeposits()
      .then(result => {
        dispatch({ type: "depositsReceived", payload: { result } })
      }, error => {
        dispatch({ type: "loadingError", payload: { error } })
      });
  }, [api]);


  const addUserDeposit = async (userDeposit: Partial<UserDeposit>) => {
    await api.userDeposits.createUserDeposit(userDeposit);
    const deposits = await api.userDeposits.getDeposits();
    dispatch({ type: "depositsReceived", payload: { result: deposits } })
  }

  const updateUserDeposit = async (userDeposit: Partial<UserDeposit>) => {
    await api.userDeposits.updateUserDeposit(userDeposit);
    const deposits = await api.userDeposits.getDeposits();
    dispatch({ type: "depositsReceived", payload: { result: deposits } })
  }

  const deleteUserDeposit = async (userDeposit: UserDeposit) => {
    await api.userDeposits.updateUserDeposit({...userDeposit, status: "DELETED"});
    const deposits = await api.userDeposits.getDeposits();
    dispatch({ type: "depositsReceived", payload: { result: deposits } })
  }

  return {
    status: state.status,
    deposits: getActiveDeposits(state.result),
    closedUserDeposits: getClosedDeposits(state.result),
    addUserDeposit,
    updateUserDeposit,
    deleteUserDeposit
  };
}

export default useUserDeposits;
