import React from "react";
import BankLogo from "../../../bankLogo/BankLogo";
import { Bank } from "../../../../api/types";
import SelectableItem from "./SelectableItem";

type Props = {
  selected: boolean;
  onSelect: (bank: Bank) => void;
  bank: Bank;
};

const BankItem = ({ selected, onSelect, bank }: Props) => (
  <div className="w-1/4 max-w-1/4 h-16 sm:h-12 sm:w-[12.5%] sm:max-w-[12.5%] p-1">
    <SelectableItem selected={selected} onClick={() => onSelect(bank)} aria-label={`Wybierz ${bank.name}`}>
      <BankLogo bank={bank} />
    </SelectableItem>
  </div>
);

export default BankItem;
