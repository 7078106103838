import React from "react";

const EmptyList = () => (
  <section className="bg-white">
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="mx-auto max-w-screen-sm text-center">
        <h1 className="mb-4 text-3xl tracking-tight font-extrabold lg:text-6xl text-primary-600">🤔 Pusto!</h1>
        <p className="text-lg font-light text-gray-500">
          Nie masz aktywnych lokat
        </p>
      </div>
    </div>
  </section>
);

export default EmptyList;
