import React from "react";
import { parseISO } from "date-fns";
import { Deposit } from "../../../api/types";
import Duration from "../Duration";
import { formatAmount, formatPercentage } from "../../utils";
import { computeDepositInterest, computeNetInterest } from "../../interestCompounding";
import ParameterItem from "./ParameterItem";
import NetInterestTooltip from "../../interest/NetInterestTooltip";
import { useWatch } from "react-hook-form";
import AmountField from "./AmountField";
import StartDateField from "./StartDateField";

type Props = {
  deposit: Deposit;
};

export type Values = {
  amount: number;
  startDate: string;
}

const DepositParameters = ({ deposit }: Props) => {
  const amount = useWatch({ name: "amount" })
  const startDate = useWatch({ name: "startDate" });
  const startDateAsDate = parseISO(startDate);

  return (
    <div>
      <h4 className="text-lg py-4">Parametry lokaty</h4>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-5 grid-rows-1">
        <AmountField min={deposit.minAmount} max={deposit.maxAmount} />
        <StartDateField />
        <ParameterItem
          label="Okres"
          content={(
            <div className="pt-2">
              <Duration durationInDays={deposit.durationInDays} durationDisplayType={deposit.durationDisplayType} />
            </div>
          )} />
        <ParameterItem
          label="Oprocentowanie"
          content={(
            <div className="pt-2">
              {formatPercentage(deposit.annualPercentageRate)}
              <span className="ml-0.5">%</span>
            </div>
          )} />
        <ParameterItem
          label="Zysk"
          content={(
            <div className="pt-2">
              <span>{amount > 0 && startDate ? formatAmount(computeNetInterest(computeDepositInterest(amount, deposit, startDateAsDate))) : "-"}</span>
              <NetInterestTooltip />
            </div>
          )} />
      </div>
    </div>
  );
};

export default DepositParameters;
