export const VALUES_TO_DAYS: Record<number, number> = {
  0: 1, // 1
  1: 30, // 30
  2: 60, // 2 * 30
  3: 90, // 3 * 30
  4: 120, // 4 * 30
  5: 150, // 5 * 30
  6: 180, // 6 * 30
  7: 270, // 9 * 30
  8: 360, // 12 * 30
  9: 720, // 24 * 30
  10: 1800, // 60 * 30
}

export const DAYS_TO_VALUES: Record<number, number> = Object.entries(VALUES_TO_DAYS)
  .reduce<Record<number, number>>((acc, entry) => (
    { ...acc, [entry[1]]: (entry[0] as unknown as number) }
  ), {});
