import React from "react";
import { useController } from "react-hook-form";
import AmountInput from "../../inputs/AmountInput";
import ParameterItem from "./ParameterItem";
import { formatAmount } from "../../utils";

type Props = {
  min: number;
  max: number | null;
}

const AmountField = ({ min, max }: Props) => {
  const { field } = useController({
    name: "amount",
    rules: {
      required: "Kwota jest wymagana",
      min: { value: min, message: `Kwota musi być większa lub równa ${formatAmount(min)}` },
      max: max ? { value: max, message: `Kwota musi być mniejsza lub równa ${formatAmount(max)}` } : undefined
    }
  })

  return (
    <ParameterItem
      htmlFor="deposit-parameters-amount"
      label="Kwota"
      name="amount"
      content={(
        <AmountInput
          id="deposit-parameters-amount"
          value={field.value}
          onChange={field.onChange} />
      )} />
  );
};

export default AmountField;
