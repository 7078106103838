import React from "react";

type Props = {
	value: string
};

const SliderTooltip = ({ value }: Props) => (
  <span className="whitespace-nowrap">
    {value}
  </span>
);

export default SliderTooltip;
