import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  className?: string;
};

const LogoutButton = ({ className }: Props) => {
  const { logout } = useAuth0();


  return <button className={className} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Wyloguj</button>;
};

export default LogoutButton;
