import React from "react";
import Feature from "../Feature";
import { BellAlertIcon, ChartBarIcon, TableCellsIcon } from "@heroicons/react/24/solid";

const FeaturesSection = () => (
  <section className="bg-white" id="features">
    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div className="max-w-screen-md mb-8 lg:mb-16">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Oszczędności w jednym miejscu</h2>
        <p className="text-gray-500 sm:text-xl">
            Zdarzyło Ci się zapomnieć o końcu lokaty odnawialnej? Brakuje Ci jednego miejsca, w którym widzisz swoje oszczędności?
            A może chciałbyś się dowiedzieć, jeżeli bardziej opłaca Ci się zerwać obecną lokatę i założyć nową na wyższy procent?
        </p>
        <p className="text-gray-500 sm:text-xl">
            To wszystko, a nawet więcej zyskasz z kontem fintee.
        </p>
      </div>
      <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
        <Feature
          icon={<BellAlertIcon className="h-6" />}
          header="Przypomnienia"
          content="Przypomnimy Ci o zbliżającym się końcu lokaty. Będziesz mógł zaplanować przeniesienie oszczędności na inny produkt, aby nie stracić zysku." />
        <Feature
          icon={<TableCellsIcon className="h-6" />}
          header="Lokaty w jednym miejscu"
          content="Z łatwością możesz zapisać na swoim koncie wybrane lokaty z naszego rankingu albo wprowadzić obecnie posiadane." />
        <Feature
          icon={<ChartBarIcon className="h-6" />}
          header="Powiadomienia o większym zysku"
          content="Powiadomimy Cię, jeżeli bardziej opłaca Ci się zerwać obecną lokatę i założyć nową na wyższy procent. Dzięki temu możesz zmaksymalizować swój zysk." />
      </div>
    </div>
  </section>
)
;

export default FeaturesSection;
