import React from "react";
import SelectableItem from "./SelectableItem";

type Props = {
  selected: boolean;
  onClick: () => void;
  label: string;
};

const ExclusionItem = ({ selected, onClick, label }: Props) => (
  <div className="w-full max-w-full h-14 sm:h-12 sm:w-1/4 sm:max-w-1/4 p-1">
    <SelectableItem selected={selected} onClick={onClick}>
      <div className="flex space-x-2 justify-center items-center p-3">
        <span className="text-xs">{label}</span>
      </div>
    </SelectableItem>
  </div>
);

export default ExclusionItem;
