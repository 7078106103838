import React from "react";
import { ReactComponent as SvgLogo } from "./logo_v2.svg";
import { classNames } from "../utils";

type Props = {
  height: number | string;
  className?: string
};

const Logo = ({ height, className }: Props) => (
  <SvgLogo height={height} className={classNames("inline-block fill-black", className)} />
);

export default Logo;
