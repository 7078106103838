// TODO:
//  add interestCompounding type
import { Deposit } from "../api/types";
import { add, differenceInDays } from "date-fns";

export const computeDepositEnd = (
  deposit: Deposit,
  depositStart: Date
) : Date => {
  const { durationInDays, durationDisplayType: displayType } = deposit;
  if (displayType === "MONTH" || displayType === "YEAR") {
    const durationInMonths = durationInDays / 30;
    return add(depositStart, { "months": durationInMonths });
  } else {
    return add(depositStart, { "days": durationInDays });
  }
}

export const computeDepositInterest = (
  amount: number,
  deposit: Deposit,
  depositStart: Date = new Date(),
  daysInYear: number = 365
) => {
  const depositEnd = computeDepositEnd(deposit, depositStart);
  return computeInterest(amount, depositStart, depositEnd, deposit.annualPercentageRate, daysInYear);
}

export const computeInterest = (
  amount: number,
  startDate: Date,
  endDate: Date,
  annualPercentageRate: number,
  daysInYear: number = 365
) => {
  const durationInDays = differenceInDays(endDate, startDate);
  return amount * (annualPercentageRate / 100) * (durationInDays / daysInYear);
}

export const computeNetInterest = (
  interest: number,
  interestTax: number = 0.19
) => {
  return interest * (1 - interestTax);
}
