import React from "react";
import { Filters } from "../types";

type Props = {
  onClick: () => void;
  filters: Filters
};

const AdditionalFiltersButton = ({ onClick, filters }: Props) => {
  const filtersEnabled = [filters.banksIds.length > 0, filters.channels.length > 0];
  const numberOfFilters = filtersEnabled.filter(enabled => enabled).length;
  return (
    <button
      type="button"
      className="shrink-0 px-5 py-2 bg-transparent text-blue-600 font-medium leading-tight rounded-2xl border border-blue-600 hover:text-blue-700 hover:bg-gray-300 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 transition duration-150 ease-in-out"
      onClick={onClick}>
      Dodatkowe filtry
      {numberOfFilters > 0 && (
        <span
          className="bg-primary text-white text-xs font-medium px-2.5 py-0.5 ml-2 -mr-2 rounded-2xl border sm:hidden">
          {numberOfFilters}
        </span>
      )}
    </button>
  );
};

export default AdditionalFiltersButton;
