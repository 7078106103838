import React from "react";
import AmountSelectOption from "./AmountSelectOption";
import { Combobox } from "@headlessui/react";

type Props = {
  value: string;
  values: number[];
};

const CustomOption = ({ value, values }: Props) => {

  const stringValue = value.replaceAll(/(\s|[zł])+/g, "");
  if (/^\d+$/.test(stringValue)) {
    const numberValue = parseInt(stringValue, 10);
    return (
      <AmountSelectOption key={value} value={numberValue} />
    );
  }

  return (
    <Combobox.Option
      className={({ active }) =>
        `relative cursor-default select-none py-2 pl-10 pr-4 ${
          active ? "bg-primary text-white" : "text-gray-900"
        }`
      }
      value={value}
    >
      {value}
    </Combobox.Option>
  )
};

export default CustomOption;
