import { AxiosInstance } from "axios";
import { Bank } from "./types";

const banks = (axios: AxiosInstance) => ({
  get: async (): Promise<Bank[]> => {
    const response = await axios.get("/banks");
    return response.data;
  }
})

export default banks;
