import { AxiosInstance } from "axios";
import { UserDeposit } from "./types";

const userDeposits = (axios: AxiosInstance) => ({
  getDeposits: async (): Promise<UserDeposit[]> => {
    const response = await axios.get<UserDeposit[]>("/users/deposits");
    return response.data;
  },
  createUserDeposit: async (userDeposit: Partial<UserDeposit>): Promise<void> => {
    await axios.post<void>("/users/deposits", userDeposit);
  },
  updateUserDeposit: async (userDeposit: Partial<UserDeposit>): Promise<void> => {
    await axios.put<void>(`/users/deposits/${userDeposit.uid}`, userDeposit);
  }
})

export default userDeposits;
