import React from "react";
import { NavLink } from "react-router-dom";
import { paths } from "./links";

const NewsletterConfirmation = () => (
  <section>
    <div className="py-20 px-4 mx-auto max-w-screen-xl text-center lg:py-32 lg:px-12">
      <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl">
        Udało się!
      </h1>
      <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48">
        Twój adres email został potwierdzony. Dziękujemy, że jesteś z nami.
      </p>
      <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
        <NavLink
          to={paths.root}
          className="bg-white inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100">
          Wróć do strony głównej
        </NavLink>
      </div>
    </div>
  </section>
);

export default NewsletterConfirmation;
