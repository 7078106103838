import React, { useState } from "react";
import { parseISO } from "date-fns";
import { Bank, Deposit } from "../../api/types";
import UserDepositModalContainer from "./UserDepositModalContainer";
import FormFields, { Values } from "./FormFields";
import { useApi } from "../../api/context";
import { useLocation, useNavigate } from "react-router-dom";
import { computeDepositEnd } from "../interestCompounding";
import formatISO from "date-fns/formatISO";

type Props = {
  banks: Bank[];
  onSave: (userDeposit: Values) => Promise<any>;
};

const AddSelectedUserDepositModal = ({ banks, onSave }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();

  const [deposit, setDeposit] = useState<Deposit | null>(null);

  const isSaveDepositAction = location.state?.action && location.state?.action?.type === "SAVE_DEPOSIT"

  const loadDefaultValues = async () => {
    return api.deposits.getByUid(location.state.action.depositId)
      .then((result) => {
        setDeposit(result)
        return api.banks.get().then((banks) => {
          const bank = banks.find(b => b.uid === result?.bankId) ?? null;

          return {
            deposit: result,
            name: result?.productName ?? "",
            amount: location.state.action.amount,
            start: location.state.action.startDate,
            end: formatISO(computeDepositEnd(result!, parseISO(location.state.action.startDate)), { representation: "date" }),
            annualPercentageRate: result?.annualPercentageRate ?? 0,
            bank
          }
        })
      });
  }

  const onSubmit = async (values: Values) => {
    await onSave(values);
    onClose();
  }

  const onClose = () => {
    navigate(location.pathname, { replace: true });
  }

  if (!isSaveDepositAction) {
    return null;
  }

  return (
    <UserDepositModalContainer
      title="Dodaj nową lokatę"
      defaultValues={loadDefaultValues}
      onSubmit={onSubmit}
      onClose={onClose}>
      <FormFields
        loading={deposit === null}
        banks={banks}
        deposit={deposit} />
    </UserDepositModalContainer>
  );
};

export default AddSelectedUserDepositModal;
