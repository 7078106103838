import React from "react";
import { Deposit } from "../../api/types";
import SelectDepositButton from "./SelectDepositButton";

type Props = {
  deposit: Deposit;
  onShowDetails: (deposit: Deposit) => void;
  onSelect: (deposit: Deposit) => void;
};

const Actions = ({ deposit, onShowDetails, onSelect }: Props) => (
  <div className="flex items-center justify-between pt-3 sm:justify-center sm:pt-8">
    <button
      type="button"
      className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center sm:hidden"
      onClick={() => onShowDetails(deposit)}>
      Szczegóły
    </button>
    <SelectDepositButton deposit={deposit} onSelect={onSelect} />
  </div>
);

export default Actions;
