import React from "react";
import NewsletterForm from "../../newsletter/NewsletterForm";

const NewsletterSection = () => (
  <section>
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="mx-auto max-w-screen-md sm:text-center">
        <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
          Zapisz się na newsletter
        </h2>
        <div className="mx-auto mb-8 max-w-2xl md:mb-12 ">
          <p className="font-light text-gray-500 sm:text-xl">
          Chcesz otrzymywać informacje o nowych funkcjonalnościach? Pozostańmy w kontakcie!
          </p>
          <p className="font-light text-gray-500 sm:text-md">Pamiętaj, że zawsze możesz się wypisać.</p>
        </div>
        <NewsletterForm />
      </div>
    </div>
  </section>
);

export default NewsletterSection;
