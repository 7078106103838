import React, { useMemo } from "react";
import SummaryBox from "./SummaryBox";
import SectionHeader from "./SectionHeader";
import { formatAmount, formatDate, getToday } from "../utils";
import { UserDeposit } from "../../api/types";
import { computeInterest, computeNetInterest } from "../interestCompounding";
import { isAfter, isBefore, parseISO } from "date-fns";

function computeSummary(deposits: UserDeposit[]) {
  const today = getToday();

  return deposits.reduce((result, deposit) => {
    result.savingsSum += deposit.amount;

    const startDate = parseISO(deposit.start);
    const endDate = parseISO(deposit.end);
    result.interestSum += deposit.amount + computeNetInterest(computeInterest(deposit.amount, startDate, endDate, deposit.annualPercentageRate));

    if (isAfter(endDate, today) || (result.nearestEndDate != null && isBefore(endDate, result.nearestEndDate))) {
      result.nearestEndDate = endDate;
    }

    return result;
  }, {
    savingsSum: 0,
    nearestEndDate: null,
    interestSum: 0
  } as DepositsSummary);
}

type Props = {
  deposits: UserDeposit[];
};

type DepositsSummary = {
  savingsSum: number;
  nearestEndDate: Date | null;
  interestSum: number;
}

const Summary = ({ deposits }: Props) => {
  const depositsSummary = useMemo(() => computeSummary(deposits), [deposits])

  return (
    <section className="py-2">
      <SectionHeader text="Podsumowanie" />
      <div className="px-2 flex flex-col space-y-2 sm:flex-row sm:items-stretch sm:space-y-0 sm:space-x-2">
        <SummaryBox header="Twoje oszczędności" value={formatAmount(depositsSummary.savingsSum)} />
        <SummaryBox header="Najbliższe zakończenie lokaty"
          value={depositsSummary.nearestEndDate ? formatDate(depositsSummary.nearestEndDate) : "-"} />
        <SummaryBox header="Kwota całkowita" value={formatAmount(depositsSummary.interestSum)} />
      </div>
    </section>
  );
};

export default Summary;
