import isDeepEqual from "fast-deep-equal/react";
import { useApi } from "../../api/context";
import { useReducer } from "react";
import { Filters } from "../filter/types";
import { Deposit } from "../../api/types";
import { useDeepCompareEffect } from "react-use";

type Action = {
  type: "depositsReceived"
  payload: { filters: Filters, result: Deposit[] }
} | {
  type: "loading",
  payload: { filters: Filters }
} | {
  type: "loadingError",
  payload: { error: any }
}

type State = {
  status: "idle" | "loading" | "success" | "error";
  selectedFilters: Filters;
  result: Deposit[];
  error: any | null
}

function depositsReducer(state: State, action: Action): State {
  switch (action.type) {
  case "loading": {
    return {
      ...state,
      status: "loading",
      selectedFilters: action.payload.filters,
      error: null
    }
  }
  case "depositsReceived": {
    if (isDeepEqual(state.selectedFilters, action.payload.filters)) {
      return {
        ...state,
        status: "success",
        result: action.payload.result,
        error: null
      };
    }
    return state;
  }
  case "loadingError": {
    return {
      ...state,
      status: "error",
      result: [],
      error: action.payload.error
    };
  }
  default:
    return state;
  }
}

const useDeposits = (filters: Filters) => {
  const api = useApi();
  const [state, dispatch] = useReducer(depositsReducer, {
    status: "idle",
    selectedFilters: filters,
    result: [],
    error: null
  });

  useDeepCompareEffect(() => {
    dispatch({ type: "loading", payload: { filters } })
    api.deposits.get({
      amount: filters.amount,
      minDaysDuration: filters.duration.min,
      maxDaysDuration: filters.duration.max,
      banksToInclude: filters.banksIds,
      expectedSalesChannels: filters.channels,
      excludeCurrentAccountRequired: filters.exclusions.currentAccountRequired,
      excludeOnlyForNewCustomers: filters.exclusions.onlyForNewCustomers,
      excludeOnlyForNewFunds: filters.exclusions.onlyForNewFunds
    })
      .then(result => {
        dispatch({ type: "depositsReceived", payload: { filters, result } })
      }, error => {
        dispatch({ type: "loadingError", payload: { error } })
      });
  }, [api, filters]);

  return { status: state.status, deposits: state.result };
}

export default useDeposits;
