import React from "react";
import DepositsListItem from "./DepositsListItem";
import TableListHeader from "./TableListHeader";
import { Bank, UserDeposit } from "../../../api/types";

type Props = {
  deposits: UserDeposit[];
  banks: Bank[];
  onUserDepositEdit: (userDeposit: UserDeposit) => void;
  onUserDepositClose: (userDeposit: UserDeposit) => void;
  onUserDepositDelete: (userDeposit: UserDeposit) => void;
};

const DepositsList = ({ deposits, banks, onUserDepositEdit, onUserDepositClose, onUserDepositDelete }: Props) => (
  <div>
    <TableListHeader />
    <div className="p-1 lg:divide-y lg:divide-slate-200 lg:rounded-lg">
      {deposits.map(deposit => (
        <DepositsListItem
          key={deposit.uid}
          userDeposit={deposit}
          onUserDepositEdit={onUserDepositEdit}
          onUserDepositClose={onUserDepositClose}
          onUserDepositDelete={onUserDepositDelete} />
      ))}
    </div>
  </div>
);

export default DepositsList;
