import React from "react";
import SelectedFilter from "./SelectedFilter";
import { Exclusions, Filters, UpdateFiltersFn } from "../types";

type Props = {
  filters: Filters;
  updateFilters: UpdateFiltersFn;
  onClick: () => void;
};

const mapExclusions = (exclusions: Exclusions): string[] => {
  const result = [];
  if (exclusions.currentAccountRequired) {
    result.push("bez wymaganego konta osobistego");
  }
  if (exclusions.onlyForNewCustomers) {
    result.push("bez promocji dla nowych klientów");
  }
  if (exclusions.onlyForNewFunds) {
    result.push("bez promocji na nowe środki");
  }

  return result;
};

const SelectedExclusions = ({ filters, updateFilters, onClick }: Props) => {
  const exclusions = mapExclusions(filters.exclusions);
  if (exclusions.length === 0) {
    return null;
  }

  const concatenated = exclusions.join(", ");

  return (
    <SelectedFilter
      text={`Wymagania: ${concatenated}`}
      onSelect={onClick}
      onRemove={() => {
        updateFilters({
          exclusions: {
            currentAccountRequired: false,
            onlyForNewCustomers: false,
            onlyForNewFunds: false
          }
        })
      }} />
  );
};

export default SelectedExclusions;
