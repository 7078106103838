import React from "react";
import AmountInput from "../../inputs/AmountInput";

type Props = {
	value: number;
	onChange: (amount: number) => void;
};

const AmountFilter = ({ value, onChange }: Props) => (
  <div>
    <label className="text-gray-700" htmlFor="amount">Kwota</label>
    <AmountInput
      id="amount"
      onChange={onChange}
      value={value} />
  </div>
);

export default AmountFilter;
