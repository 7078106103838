import React from "react";
import useScrollToTop from "../components/useScrollToTop/useScrollToTop";

const CookiesInfo = () => {
  useScrollToTop();
  return (
    <section className="bg-white text-justify" id="features">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">

        <h2 className="mb-4 text-4xl tracking-tight font-extrabold">
          Pliki cookies (ciasteczka)
        </h2>
        <p>

          <h3 className="my-4 text-2xl font-extrabold">Czym są pliki cookies i do czego służą?</h3>
          <p>
            Pliki cookies to niewielkie pliki tekstowe, które są zapisywane na Twoim urządzeniu podczas odwiedzania naszej Strony internetowej. Pliki
            te są przesyłane przez serwis internetowy do przeglądarki. Każdy plik cookie posiada anonimowy identyfikator i pozwala zidentyfikować
            urządzenie, w którego pamięci został zapisany przez określony czas. Pliki cookies są zapisywane w celu rozpoznania Twojego urządzenia,
            poznania Twoich preferencji, a także zbierania informacji na temat Twoich działań na naszej Stronie.
          </p>
          <h3 className="my-4 text-2xl font-extrabold">Jakich plików cookies używamy?</h3>

          <p>
            Możemy używać następujących rodzajów plików cookies:

            <ul className="m-8" style={{ listStyleType: "disc" }}>
              <li>Cookies sesyjne – pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis lub Serwisy zewnętrzne podczas jednej sesji
                danego Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia Użytkownika
              </li>
              <li>Cookies trwałe – dzięki nim korzystanie ze Strony internetowej jest łatwiejsze (np. zapamiętują wybraną rozdzielczość, układ
                treści). Mogą być
                wykorzystywane do różnych celów, w tym do zapamiętywania Twoich preferencji i wyborów podczas korzystania ze Strony internetowej. Te
                pliki
                cookies pozostają w pamięci przeglądarki przez dłuższy okres.
              </li>
              <li>Cookies wewnętrzne – są zapisywane przez obecnie przeglądaną Stronę internetową. Służą poprawie jakości korzystania ze Strony
                internetowej.
              </li>
              <li>Cookies zewnętrzne (third party cookies) – są wykorzystywane przez strony trzecie, jak np. portale społecznościowe, w celu śledzenia
                Twoich wizyt na różnych stronach internetowych, na których zamieszczają reklamy.
              </li>
            </ul>
          </p>
          <p>
            Pliki cookies używane przez naszą Stronę, można podzielić na następujące kategorie:

            <ul className="m-8">
              <li>A. Pliki cookies konieczne</li>
              <li>B. Pliki cookies wydajności i personalizacji</li>
            </ul>

            <h4 className="mb-4 font-bold">Pliki cookies konieczne: Zawsze Aktywne</h4>
            <p>
              Bez tych plików nie można poruszać się po witrynie ani korzystać z jej funkcji. Twoje dane przetwarzamy w oparciu o art. 6 (1) f) RODO.
              Możesz wyrazić sprzeciw wobec takiego przetwarzania Twoich danych osobowych, lecz może uniemożliwić to korzystanie z naszej Strony
              internetowej.
            </p>

            <h4 className="my-4 font-bold">Pliki cookies wydajności i personalizacji (checkbox do zaznaczenia):</h4>
            <p>
              Gromadzą informacje, które mówią, w jaki sposób korzystasz z witryny. Pliki te są wykorzystywane po to, aby usprawnić działanie Strony.
              Dodatkowo zapisywane są informacje o Twoich wyborach, dzięki którym możemy zaproponować ulepszone i spersonalizowane funkcje na naszej
              Stronie.
              Pliki te są zapisywane tylko za Twoją zgodą, wyrażoną poprzez kliknięcie w odpowiednie pole wyboru (checkbox), które pojawi się po
              otwarciu naszej Strony. Swoją zgodę możesz wycofać w każdej chwili.
            </p>
          </p>

          <h3 className="my-4 text-2xl font-extrabold">Jak długo przechowujemy pliki cookies?</h3>

          <p>
            Cookies sesyjne pozostaną na Twoim urządzeniu do momentu opuszczenia przez Ciebie naszej Strony lub wyłączenia przeglądarki internetowej.
            Trwałe cookies będą przechowywane na Twoim urządzeniu maksymalnie przez okres 12 miesięcy lub do czasu wycofania przez Ciebie zgody.
          </p>

          <h3 className="my-4 text-2xl font-extrabold">Jak zarządzać plikami cookies?</h3>

          <p>
            Pamiętaj, że jeśli nie wyrazisz zgody na działanie plików cookies, nie będziesz mógł w pełni korzystać z niektórych elementów naszej
            Strony
            np. nie będziesz mógł korzystać z funkcji personalizacji.
          </p>

          <p>
            Pamiętaj o skonfigurowaniu wszystkich przeglądarek na wszystkich urządzeniach. Skorzystaj z dokumentacji przeglądarki, której
            używasz by dowiedzieć się jak skonfigurować ustawienia dotyczące cookies.
          </p>

          <p>
            Sposoby konfigurowania ustawień dotyczących plików cookies w niektórych przeglądarkach można znaleźć pod tymi adresami:

            <ul className="m-8" style={{ listStyleType: "disc" }}>
              <li><a rel="nofollow" href="https://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647">Google Chrome</a></li>
              <li><a rel="nofollow" href="https://support.mozilla.org/pl/kb/usuwanie-ciasteczek">Mozilla Firefox</a></li>
              <li><a rel="nofollow" href="https://help.opera.com/pl/latest/security-and-privacy/#Czyszczenie-danych-przegl%C4%85dania">Opera</a></li>
              <li><a rel="nofollow" href="https://support.microsoft.com/kb/278835/pl">Microsoft Internet Explorer</a></li>
              <li><a rel="nofollow"
                href="https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft
                Edge</a></li>
            </ul>
          </p>
        </p>

        <h3 className="my-4 text-2xl font-extrabold">Zmiany w Polityce Cookies</h3>
        <p>
          Zastrzegamy sobie prawo do dowolnej zmiany niniejszej Polityki Cookies bez konieczności informowania o tym użytkowników. <br />
          Wprowadzone zmiany w Polityce Cookies zawsze będą publikowane na tej Stronie.
        </p>

      </div>
    </section>
  );
};

export default CookiesInfo;
