import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

type Props = {
  onConfirm: () => void;
  onClose: () => void;
};

const DeleteConfirmationModal = ({ onConfirm, onClose }: Props) => (
  <Transition appear show={true} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className="w-full max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900 p-4"
              >
                Uwaga
              </Dialog.Title>
              <p className="leading-6 text-gray-900 p-4">
                Czy na pewno chcesz usunąć wybraną lokatę?
              </p>
              <div className="flex justify-between p-4">
                <button
                  type="button"
                  className="text-primary hover:text-white border border-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={onClose}>
                  Zamknij
                </button>
                <button
                  type="button"
                  className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer"
                  onClick={onConfirm}>
                  Potwierdź
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default DeleteConfirmationModal;
