import React, { PropsWithChildren, useEffect, useState } from "react";
import { useApi } from "../../api/context";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingBox from "../loading/LoadingBox";

const AuthLoader = ({ children }: PropsWithChildren) => {
  const api = useApi();
  const auth = useAuth0();
  const [tokenLoaded, setTokenLoaded] = useState(false)

  useEffect(() => {
    if (!auth.isLoading) {
      if (auth.isAuthenticated) {
        auth.getAccessTokenSilently()
          .then((token) => {
            api.setAuthorization(token);
            setTokenLoaded(true);
          })
      } else {
        api.clearAuthorization();
        setTokenLoaded(true)
      }
    }
  }, [auth.isAuthenticated, auth, api])

  return (
    <LoadingBox description="Loading..." loading={auth.isLoading || !tokenLoaded} blocking={true}>
      {children}
    </LoadingBox>
  );
}

export default AuthLoader;
