import React from "react";
import { Bank, UserDeposit } from "../../../api/types";
import ItemValue from "./ItemValue";
import ItemDepositName from "./ItemDepositName";
import { formatAmount, formatPercentage } from "../../utils";
import DepositDaysLeft from "./DepositDaysLeft";
import InterestCompounding from "./InterestCompounding";

type Props = {
  userDeposit: UserDeposit;
  bank: Bank | null;
  daysLeft: number;
}

const DepositsListItemSummary = ({ userDeposit, bank, daysLeft }: Props) => (
  <div
    className="flex flex-wrap p-2 bg-white">
    <div className="w-full flex items-center md:w-1/3 lg:w-[35%] lg:flex-row">
      <ItemDepositName userDeposit={userDeposit} bank={bank} />
    </div>
    <div className="flex flex-col w-1/2 p-1 md:w-1/3 lg:w-[20%]">
      {userDeposit.status !== "ACTIVE" ? (
        <ItemValue
          label="Zakończenie"
          value="Zamknięta" />
      ) : (
        <DepositDaysLeft daysLeft={daysLeft} />
      )}
    </div>
    <div className="flex flex-col w-1/2 p-1 md:w-1/3 lg:w-[15%]">
      <ItemValue
        label="Kwota"
        value={formatAmount(userDeposit.amount)} />
    </div>
    <div className="flex flex-col w-1/2 p-1 md:w-1/3 lg:w-[15%]">
      <ItemValue
        label="Oprocentowanie"
        value={(
          <>
            {formatPercentage(userDeposit.annualPercentageRate)}
            <span className="ml-0.5 text-sm">%</span>
          </>
        )} />
    </div>
    <div className="flex flex-col w-1/2 p-1 md:w-1/3 lg:w-[15%]">
      <InterestCompounding userDeposit={userDeposit} finished={daysLeft <= 0} />
    </div>
  </div>
);

export default DepositsListItemSummary;
