import React from "react";
import { Bank } from "../../api/types";
import { ReactComponent as DefaultBankLogo } from "./defaultBankLogo.svg";

type Props = {
  bank: Bank | null;
};

const BankLogo = ({ bank }: Props) => {
  if (bank && bank.logo != null) {
    return (
      <img className="h-full w-full object-scale-down" src={bank.logo} alt={`Logo banku: ${bank.name}`} />
    );
  }

  return <DefaultBankLogo className="h-full w-full object-scale-down fill-gray-400" />;
};

export default BankLogo;
