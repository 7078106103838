import React from "react";
import { Bank } from "../../api/types";
import UserDepositModalContainer from "./UserDepositModalContainer";
import FormFields, { Values } from "./FormFields";

type Props = {
  banks: Bank[];
  onSave: (userDeposit: Values) => Promise<any>;
  onClose: () => void;
};

const AddUserDepositModal = ({ banks, onSave, onClose }: Props) => {
  const onSubmit = async (values: Values) => {
    return onSave(values);
  }

  return (
    <UserDepositModalContainer
      title="Dodaj nową lokatę"
      defaultValues={{
        deposit: null,
        name: "",
        amount: 0,
        start: "",
        end: "",
        annualPercentageRate: 0,
        bank: null,
      }}
      onSubmit={onSubmit}
      onClose={onClose}>
      <FormFields
        banks={banks}
        deposit={null} />
    </UserDepositModalContainer>
  );
};

export default AddUserDepositModal;
