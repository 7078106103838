import React from "react";
import FormItem from "./FormItem";
import { useFormContext } from "react-hook-form";
import DateInput from "../inputs/DateInput";
import { isBefore, parseISO } from "date-fns";

const StartDateField = () => {
  const { register } = useFormContext();

  return (
    <FormItem
      htmlFor="user-deposit-start-date"
      label="Data rozpoczęcia"
      name="startDate"
      content={(
        <DateInput
          id="user-deposit-start-date"
          {...register("start", {
            required: "Data rozpoczęcia jest wymagana",
            validate: (value, formValues) => {
              if (value && formValues.endDate && !isBefore(parseISO(value), parseISO(formValues.endDate))) {
                return "Data rozpoczęcia musi być wcześniejsza niż data zakończenia"
              }
            }
          })} />
      )}
    />
  );
};

export default StartDateField;
