import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../utils";
import { NavLink } from "react-router-dom";
import { paths } from "../../../routes/links";

type Props = {
  onManualAdd: () => void;
};

const AddDepositButton = ({ onManualAdd }: Props) => (
  <Menu as="div" className="relative inline-block text-left">
    <div>
      <Menu.Button
        className="bg-primary hover:bg-blue-800 font-medium rounded-lg text-white text-sm p-1.5 sm:px-2.5 text-center inline-flex items-center gap-x-1.5">
        Dodaj lokatę
        <ChevronDownIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />
      </Menu.Button>
    </div>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          <Menu.Item>
            {({ active }) => (
              <NavLink
                to={paths.deposits}
                className={classNames(
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  "block px-4 py-2 text-sm"
                )}>
                Z listy dostępnych ofert
              </NavLink>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onManualAdd}
                className={classNames(
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  "block px-4 py-2 text-sm w-full text-left"
                )}
              >
                Wprowadź ręcznie
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
);

export default AddDepositButton;
