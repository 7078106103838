import React, { ReactNode } from "react";

type Props = {
  icon: ReactNode;
  header: string;
  content: string;
};

const Feature = ({ icon, header, content }: Props) => (
  <div className="bg-light border border-gray-200 p-4 rounded-2xl">
    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-secondary lg:h-12 lg:w-12 text-white">
      {icon}
    </div>
    <h3 className="mb-2 text-xl font-bold">{header}</h3>
    <p className="text-gray-500">{content}</p>
  </div>
);

export default Feature;
