import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { links } from "../../routes/links";
import { Duration } from "../filter/types";

type Props = {
  amount: number;
  duration: Duration;
};

const QuickFilterLink = ({ amount, duration, children }: PropsWithChildren<Props>) => (
  <dd className="px-1 py-1">
    <Link className="text-primary transition-colors duration-300 hover:text-secondary underline" to={links.deposits(amount, duration)}>{children}</Link>
  </dd>
);

export default QuickFilterLink;
