import React from "react";
import FormItem from "./FormItem";
import { useFormContext } from "react-hook-form";

const AnnualPercentageRateField = () => {
  const { register } = useFormContext();

  return (
    <FormItem
      htmlFor="user-deposit-annual-percentage-rage"
      label="Oprocentowanie"
      name="annualPercentageRate"
      content={(
        <div
          className="relative w-full max-w-xs mt-1 cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          <input
            id="user-deposit-annual-percentage-rage"
            type="number"
            className="w-full border-none h-[36px] py-2 px-3 text-sm leading-5 text-gray-900 focus:ring-0"
            min={0}
            max={100}
            step={0.01}
            {...register("annualPercentageRate", {
              required: "Oprocentowanie jest wymagane"
            })} />
        </div>
      )}
    />
  )
};

export default AnnualPercentageRateField;
