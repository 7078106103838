export const SUPPORTED_DURATION = [1, 30, 2 * 30, 3 * 30, 4 * 30, 5 * 30, 6 * 30, 9 * 30, 12 * 30, 24 * 30, 60 * 30]

const durationFormatter = (durationInDays: number): string => {
  switch (durationInDays) {
  case SUPPORTED_DURATION[0]:
    return "1 dzień"
  case SUPPORTED_DURATION[1]:
    return "30 dni"
  case SUPPORTED_DURATION[2]:
  case SUPPORTED_DURATION[3]:
  case SUPPORTED_DURATION[4]:
    return `${durationInDays / 30} miesiące`
  case SUPPORTED_DURATION[5]:
  case SUPPORTED_DURATION[6]:
  case SUPPORTED_DURATION[7]:
    return `${durationInDays / 30} miesięcy`
  case SUPPORTED_DURATION[8]:
    return `1 rok`
  case SUPPORTED_DURATION[9]:
    return `2 lata`
  case SUPPORTED_DURATION[10]:
    return `5 lat`
  }

  throw Error("Unsupported duration");
}

export default durationFormatter;
