import React, { PropsWithChildren } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import SeparatorHeader from "./SeparatorHeader";

type Props = {};

/*
* */

const Separator = ({ children }: PropsWithChildren<Props>) => (
  <Disclosure>
    {({ open }) => (
      <div className="pt-2">
        <SeparatorHeader open={open} />
        <Transition
          enter="transition duration-100 ease-in"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-75 ease-in"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <Disclosure.Panel>
            {children}
          </Disclosure.Panel>
        </Transition>
      </div>
    )}
  </Disclosure>
);

export default Separator;
