import React, { useState } from "react";
import { Bank, Deposit } from "../../api/types";
import AnnualPercentageRate from "./AnnualPercentageRate";
import ShortDetails from "./ShortDetails";
import Actions from "./Actions";
import BankLogo from "../bankLogo/BankLogo";
import DetailsModal from "./DetailsModal";
import DepositDetails from "./details/DepositDetails";
import { ChannelAvailability } from "./ChannelAvailability";
import useAnalyticsTracker from "../analytics/useAnalyticsTracker";

type Props = {
  selectedAmount: number;
  deposit: Deposit;
  bank: Bank | null;
  onSelect: (deposit: Deposit) => void;
};

const DepositItem = ({ bank, selectedAmount, deposit, onSelect }: Props) => {
  const { depositDetailsShown } = useAnalyticsTracker();
  const [modalVisible, setModalVisible] = useState(false);
  const onShowDetails = () => {
    setModalVisible(true);
    depositDetailsShown(deposit, bank);
  };

  return (
    <div className="border-2 border-gray-100 rounded-lg bg-white" data-deposit-id={deposit.uid}>
      <div className="p-2">
        <div className="flex flex-col sm:flex-row w-full">
          <div className="flex-1 p-2 sm:pl-2 sm:pt-2 sm:pb-0 sm:pr-0">
            <div className="flex flex-1 justify-between items-center">
              <div className="flex-shrink-0 w-1/3 sm:w-28 h-16">
                <BankLogo bank={bank} />
              </div>
              <div className="sm:hidden">
                <AnnualPercentageRate deposit={deposit} />
              </div>
              <div className="hidden sm:block flex-1 px-4">
                <h1 className="font-semibold text-dark text-lg">{deposit.productName}</h1>
                <ChannelAvailability deposit={deposit} />
              </div>
            </div>
            <div className="hidden sm:block">
              <DepositDetails deposit={deposit} />
            </div>
          </div>

          <h1 className="font-semibold text-dark text-lg px-4 sm:hidden">{deposit.productName}</h1>

          <div className="flex flex-col sm:border-l sm:border-gray-200 p-4 sm:pl-0 sm:pr-4 sm:pt-4 sm:pb-4 sm:w-1/4">

            <div className="hidden sm:block p-4">
              <AnnualPercentageRate deposit={deposit} />
            </div>

            <ShortDetails selectedAmount={selectedAmount} deposit={deposit} />

            <Actions
              deposit={deposit}
              onShowDetails={onShowDetails}
              onSelect={onSelect} />

            <DetailsModal
              deposit={deposit}
              isOpen={modalVisible}
              onClose={() => setModalVisible(false)}
              onSelect={onSelect} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositItem;
