import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import durationFormatter from "./durationFormatter";
import SliderTooltip from "./SliderTooltip";
import { DAYS_TO_VALUES, VALUES_TO_DAYS } from "./sliderValues";
import { Duration } from "../types";
import "./rc-slider-overrides.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

type Props = {
  onChange: (duration: Duration) => void;
  onAfterChange: (duration: Duration) => void;
  value: Duration;
};

/**
 * Slider values:
 *   1 day, 30 days, 2, 3, 4, 5, 6, 9 months, 1, 2, 5 years
 */
const DurationSlider = (props: Props) => {
  const max = Object.keys(VALUES_TO_DAYS).length - 1;
  const sliderValue = [DAYS_TO_VALUES[props.value.min], DAYS_TO_VALUES[props.value.max]];

  return (
    <div className="block w-full px-4 py-2 mt-2">
      <Range
        className="my-1.5"
        tipFormatter={(value: number) => {
          return (<SliderTooltip value={durationFormatter(VALUES_TO_DAYS[value])} />)
        }}
        max={max}
        onChange={(value) => {
          props.onChange({ min: VALUES_TO_DAYS[value[0]], max: VALUES_TO_DAYS[value[1]] })
        }}
        onAfterChange={(value) => {
          props.onAfterChange({ min: VALUES_TO_DAYS[value[0]], max: VALUES_TO_DAYS[value[1]] });
        }}
        value={sliderValue}
      />
    </div>
  );
};

export default DurationSlider;
